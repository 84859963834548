import React, { ChangeEventHandler, FC, useEffect, useMemo, useState } from 'react';
import {
  ActionIcon,
  Anchor,
  Box,
  Chip,
  Group,
  Indicator,
  MultiSelect,
  Select,
  Stack,
  Text,
  TextInput,
  Title,
  Tooltip,
} from '@mantine/core';
import { IconAdjustments, IconSearch } from '@tabler/icons-react';
import { useHashDisclosure } from '@core/router';
import FullDrawer from '@shared/components/drawer/FullDrawer';
import { Zone } from '@modules/iot/zones/model';
import { useLocation, useNavigate } from 'react-router-dom';
import { SensorUtils } from '@modules/iot/sensors/utils';
import { parseQueries, stringifyQueries } from '@shared/utils/queries';
import { ActiveSensor, Sensor } from '@modules/iot/sensors/model';
import { useDebouncedCallback } from 'use-debounce';
import DrawerContent from '@shared/components/drawer/DrawerContent';
import { pipe } from 'fp-ts/function';
import * as R from 'fp-ts/Record';
import * as NEA from 'fp-ts/NonEmptyArray';
import * as O from 'fp-ts/Option';
import { Threshold } from '@modules/iot/model';
import ThresholdSelect from '@modules/iot/components/threshold-select/ThresholdSelect';
import { getDropdownOptionsFromEnumLabels } from '@shared/utils/enum';
import { Sinafis } from '@modules/iot/sensors/sinafis/model';
import { AlertTrigger } from '@modules/iot/alert-triggers/model';
import identifierLabel = Sinafis.Probe.identifierLabel;
import alertMeasureTypeFromIdentifier = AlertTrigger.alertMeasureTypeFromIdentifier;
import Type = Sensor.Type;
import alertMeasureTypeBySensorType = AlertTrigger.alertMeasureTypeBySensorType;
import { Measures } from '@shared/modules/measures/model';

interface SensorsFiltersProps {
  zones: Array<Zone>;
}

const SensorsFilters: FC<SensorsFiltersProps> = ({ zones }) => {
  const { search, hash } = useLocation();
  const navigate = useNavigate();

  const [filters, setFilters] = useState(() => SensorUtils.sensorsFilterParser(parseQueries(search)));
  const [searchValue, setSearchValue] = useState(() => filters.search ?? '');

  const [drawerOpen, drawerTriggers] = useHashDisclosure('#filters');

  useEffect(() => {
    setFilters(SensorUtils.sensorsFilterParser(parseQueries(search)));
  }, [search]);

  const handleApplyFilters = (filterToApply: Partial<ActiveSensor.Filter>) => {
    const newFilters = { ...filters, ...filterToApply };

    setFilters(newFilters);

    navigate({ search: stringifyQueries(newFilters), hash }, { replace: true });
  };

  const debouncedApplyFilters = useDebouncedCallback(handleApplyFilters, 500);

  const handleResetFilters = () =>
    handleApplyFilters({
      ...pipe(
        filters,
        R.map(() => null),
      ),
      search: filters.search,
    });

  const handleSearchChange: ChangeEventHandler<HTMLInputElement> = e => {
    const value = e.target.value;

    setSearchValue(value);
    debouncedApplyFilters({ search: value });
  };

  const handleZoneIdsChange = (zoneId: Array<Zone.Id>) =>
    handleApplyFilters({ zoneId: pipe(zoneId, NEA.fromArray, O.toNullable) });

  const handleSensorTypeChange = (type: Sensor.Type | 'all') =>
    handleApplyFilters({ sensorType: type === 'all' ? null : type, sinafisProbeIdentifier: null, alertType: null });

  const handleAlertLevelChange = (alertLevel: Threshold.Level | null) => handleApplyFilters({ alertLevel });
  const handleSinafisProbeIdentifierChange = (sinafisProbeIdentifier: Sinafis.Probe.Identifier | null) =>
    handleApplyFilters({ sinafisProbeIdentifier, alertType: null });

  const handleAlertTypeChange = (alertType: Measures.Type | null) => handleApplyFilters({ alertType });

  const hasFilters = useMemo(
    () =>
      !pipe(
        filters,
        R.filterWithIndex((key, value) => key !== 'search' && value != null),
        R.isEmpty,
      ),
    [filters],
  );

  const showSinafisProbeIdentifier = !filters.sensorType || filters.sensorType === Type.Sinafis;

  const alertTypeData = useMemo(() => {
    switch (filters.sensorType) {
      case Type.Sinafis:
        return filters.sinafisProbeIdentifier
          ? alertMeasureTypeFromIdentifier[filters.sensorType][filters.sinafisProbeIdentifier]
          : alertMeasureTypeBySensorType[filters.sensorType];
      case null:
        // case 'all' includes sinafis
        return alertMeasureTypeBySensorType[Sensor.Type.Sinafis];
      default:
        // hidden
        return alertMeasureTypeBySensorType[filters.sensorType];
    }
  }, [filters.sensorType, filters.sinafisProbeIdentifier]);

  return (
    <>
      <TextInput
        value={searchValue}
        onChange={handleSearchChange}
        icon={<IconSearch size={20} />}
        w={300}
        placeholder="Rechercher une sonde, groupe"
      />

      <Tooltip label="Filtrer" position="bottom">
        <Indicator color="tertiary.5" disabled={!hasFilters} withBorder size={13}>
          <ActionIcon size={36} variant="outline" c="primary.5" color="gray.2" onClick={drawerTriggers.toggle}>
            <IconAdjustments size={22} strokeWidth={1.3} />
          </ActionIcon>
        </Indicator>
      </Tooltip>

      <FullDrawer opened={drawerOpen} width={530} onClose={drawerTriggers.close}>
        <DrawerContent
          top={
            <Group>
              <Title size="h3" sx={{ flex: '1 1 auto' }}>
                Filtres
              </Title>

              <Anchor component="button" c="dark.3" size="sm" fw={600} onClick={handleResetFilters}>
                Réinitialiser
              </Anchor>
            </Group>
          }>
          <Stack>
            <MultiSelect
              label="Groupe(s)"
              placeholder="Sélectionner"
              value={filters.zoneId ?? []}
              data={zones.map(zone => ({ value: zone.id, label: zone.name }))}
              onChange={handleZoneIdsChange}
              nothingFound="Aucun groupe"
              clearable
            />

            <Box>
              <Text fz={14} fw={600} c="gray.9">
                Marque de sonde
              </Text>

              <Group mt={5} spacing={10}>
                <Chip.Group value={filters.sensorType ?? 'all'} onChange={handleSensorTypeChange}>
                  <Chip value="all" radius="xs">
                    Tout
                  </Chip>

                  {Object.values(Sensor.Type).map(type => (
                    <Chip key={type} value={type} radius="xs">
                      {Sensor.typeTitle[type]}
                    </Chip>
                  ))}
                </Chip.Group>
              </Group>
            </Box>

            {showSinafisProbeIdentifier && (
              <Select
                label="Configuration du capteur"
                placeholder="Sélectionner"
                data={getDropdownOptionsFromEnumLabels(identifierLabel)}
                onChange={handleSinafisProbeIdentifierChange}
                value={filters.sinafisProbeIdentifier}
                clearable
              />
            )}
            {showSinafisProbeIdentifier && (
              <Select
                label="Type d'alerte"
                placeholder="Sélectionner"
                data={alertTypeData.map(value => ({ value, label: Measures.typeTitle[value] }))}
                disabled={!showSinafisProbeIdentifier || !filters.sinafisProbeIdentifier}
                onChange={handleAlertTypeChange}
                value={filters.alertType}
                clearable
              />
            )}

            <ThresholdSelect
              label="Niveau d'alerte"
              placeholder="Sélectionner"
              value={filters.alertLevel}
              onChange={handleAlertLevelChange}
              clearable
            />
          </Stack>
        </DrawerContent>
      </FullDrawer>
    </>
  );
};

export default SensorsFilters;
