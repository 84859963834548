import React, { FC, useState } from 'react';
import { defer, defineLoader, httpTaskToResponseTask, useLoader } from '@core/router/loader';
import { pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';
import { defineRoute } from '@core/router';
import Page from '@layout/page/Page';
import { useProfile } from '@modules/profile/loader';
import Previsions from '@shared/modules/previsions/components/Previsions';
import { Box, Card, SimpleGrid, Skeleton, Stack, Text } from '@mantine/core';
import { ZonesService } from '@modules/iot/zones/service';
import DashboardStatsCard from '@modules/iot/dashboard/components/DashboardStatsCard';
import { DashboardService as IotDashboardService } from '@modules/iot/dashboard/service';
import { Threshold } from '@modules/iot/model';
import { parseQueriesFormUrl } from '@shared/utils/queries';
import { z } from 'zod';
import DashboardPieChart from '@modules/dashboard/components/DashboardPieChart';
import Level = Threshold.Level;
import DashboardActiveSensorsCard from '@modules/iot/dashboard/components/DashboardActiveSensorsCard';
import DashboardPieChartPlaceholder from '@modules/iot/dashboard/components/placeholder/DashboardPieChartPlaceholder';
import { Dashboard } from '@modules/dashboard/model';
import Await from '@core/router/components/Await';
import DashboardPest from '@modules/dashboard/components/DashboardPest';
import { renderOptional } from '@shared/utils/render';
import { DashboardHooks } from '@modules/dashboard/hooks';

const queriesSchema = z.object({
  alertLevel: z.nativeEnum(Level).nullable().default(null),
});

const loader = defineLoader({
  handler: ({ request }) => {
    const queries = queriesSchema.parse(parseQueriesFormUrl(request.url));
    return pipe(
      ZonesService.getZones(),
      TE.map(zones =>
        defer({
          zones,
          dashboard: httpTaskToResponseTask(IotDashboardService.getDashboard(false, queries.alertLevel)),
        }),
      ),
      httpTaskToResponseTask,
    );
  },
});

const DashBoardPage: FC = () => {
  const profile = useProfile();
  const { zones, dashboard } = useLoader<typeof loader>();

  const [filter, setFilter] = useState<Dashboard.Filter | null>(null);

  const { context, weather, disease, pest } = DashboardHooks.useDashboardAsyncData(filter);

  return (
    <Page top={`Bonjour, ${profile.firstname} 👋`} seoTitle="Accueil">
      <Box p={20}>
        <Previsions context={context} weather={weather} disease={disease} zones={zones} setFilter={setFilter} />
        <SimpleGrid cols={2} spacing={15} pt={20}>
          <Stack spacing={10}>
            <Text color="dark.5" weight={700} fz={18} lh={1.45}>
              Mon parc de sondes
            </Text>
            <DashboardActiveSensorsCard dashboard={dashboard} />
            <Card shadow="0px 4px 10px rgba(0, 0, 0, 0.1)" radius={10} p={18} withBorder={false} mt={2}>
              <SimpleGrid cols={2}>
                <Await resolve={dashboard} fallback={<DashboardPieChartPlaceholder mah={135} />}>
                  {({ alerts }) => <DashboardPieChart mah={135} alerts={alerts} />}
                </Await>
                <Stack>
                  <Await
                    resolve={dashboard}
                    fallback={
                      <>
                        <Skeleton radius={8} style={{ flex: 1 }} />
                        <Skeleton radius={8} style={{ flex: 1 }} />
                      </>
                    }>
                    {({ alerts }) => (
                      <>
                        <DashboardStatsCard count={alerts.medium} level={Level.Alert} py={4} />
                        <DashboardStatsCard count={alerts.high} level={Level.Critical} py={4} />
                      </>
                    )}
                  </Await>
                </Stack>
              </SimpleGrid>
            </Card>
          </Stack>
          <Stack spacing={10}>
            <Text c="dark.5" fz={18} fw={700} lh={1.45}>
              Vigilance bioagresseurs
            </Text>
            {renderOptional(
              pest,
              pest => (
                <DashboardPest pest={pest} />
              ),
              () => (
                <Skeleton h="100%" />
              ),
            )}
          </Stack>
        </SimpleGrid>
      </Box>
    </Page>
  );
};

const dashBoardRoute = defineRoute({
  component: DashBoardPage,
  loader,
});

export default dashBoardRoute;
