import React, { FC, useEffect, useState } from 'react';
import { defineRoute, preventActionLeave, usePreserveNavigate } from '@core/router';
import GoogleMaps from '@shared/modules/maps/components/GoogleMaps';
import { ActionIcon, Box, Card, Group, SimpleGrid, Text, ThemeIcon } from '@mantine/core';
import { useMatches, useOutlet } from 'react-router-dom';
import LightDrawer from '@shared/components/drawer/LightDrawer';
import { defineLoader, httpTaskToResponseTask, useLoader } from '@core/router/loader';
import { pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';
import Supercluster from 'supercluster';
import { Maps } from '@shared/modules/maps/model';
import { CustomPointFeature, MAX_ZOOM } from '@shared/hooks/supercluster';
import { parseQueriesFormUrl } from '@shared/utils/queries';
import { isFilterEmpty } from '@shared/modules/filter';
import { IconDownload, IconExclamationCircle, IconX } from '@tabler/icons-react';
import { AlertReportsService } from '@modules/alert-reports/service';
import { AlertReport } from '@modules/alert-reports/model';
import MapClustering from '@shared/modules/maps/components/MapClustering';
import AlertReportPin from '@modules/alert-reports/map/components/AlertReportPin';
import GoogleMapsOverlay from '@shared/modules/maps/components/GoogleMapsOverlay';
import { AlertReportUtils } from '@modules/alert-reports/utils';
import alertReportsFilterParser = AlertReportUtils.alertReportsFilterParser;
import FullDrawer from '@shared/components/drawer/FullDrawer';
import AlertReportHeader from '@modules/alert-reports/map/components/AlertReportHeader';
import Page from '@layout/page/Page';
import RouterId = AlertReport.Filter.RouterId;
import { defineAction, useAction } from '@core/router/action';
import * as D from 'fp-ts/Date';
import * as IO from 'fp-ts/IO';
import { getYear } from 'date-fns';

const loader = defineLoader({
  handler: ({ request }) => {
    const createCluster = (markers: Array<AlertReport.Marker>) => {
      const cluster = new Supercluster<CustomPointFeature<AlertReport.Marker>>({ radius: 75, maxZoom: MAX_ZOOM });

      const points: Array<Supercluster.PointFeature<CustomPointFeature<AlertReport.Marker>>> = markers.map(marker => ({
        type: 'Feature' as const,
        properties: { cluster: false, marker },
        geometry: {
          type: 'Point' as const,
          coordinates: [marker.location.longitude, marker.location.latitude],
        },
      }));

      cluster.load(points);

      return cluster;
    };

    const filters = alertReportsFilterParser(parseQueriesFormUrl(request.url));

    return pipe(
      TE.Do,
      TE.apS('counters', AlertReportsService.getCounters()),
      TE.apS('markers', AlertReportsService.getMapAlertReports(filters)),
      TE.let('supercluster', ({ markers }) => createCluster(markers)),
      TE.let('filters', () => filters),
      httpTaskToResponseTask,
    );
  },
});

const actions = {
  exportAlertReports: defineAction({
    type: 'exportAlertReports',
    handler: AlertReportsService.exportAlertReports,
  }),
};

const AlertReportMap: FC = () => {
  const outlet = useOutlet();
  const navigate = usePreserveNavigate();
  const { markers, supercluster, filters, counters } = useLoader<typeof loader>();

  const [exportLoading, exportAlertReports] = useAction(actions.exportAlertReports);

  const showPlaceholder = !isFilterEmpty(filters) && markers.length === 0;

  const [placeholderOpen, setPlaceholderOpen] = useState(showPlaceholder);

  useEffect(() => {
    setPlaceholderOpen(!isFilterEmpty(filters) && markers.length === 0);
  }, [filters, markers.length]);
  const handleClose = () => navigate('..', { replace: true, relative: 'path' });

  const matches = useMatches();

  const isFilterMatch = matches.some(({ id }) => id.includes(RouterId.Listing));

  const currentYear = pipe(D.create, IO.map(getYear));

  return (
    <Page top={<AlertReportHeader markers={markers} />} p={0}>
      <GoogleMaps
        options={{
          ...Maps.defaultOptions,
          zoomControl: true,
        }}>
        {map => (
          <MapClustering map={map} markers={markers} supercluster={supercluster}>
            {marker => (
              <GoogleMapsOverlay
                key={marker.id}
                position={{ lng: marker.location.longitude, lat: marker.location.latitude }}
                map={map}>
                <AlertReportPin marker={marker} />
              </GoogleMapsOverlay>
            )}
          </MapClustering>
        )}
      </GoogleMaps>

      {showPlaceholder ? (
        <LightDrawer opened={placeholderOpen} bottom={110}>
          <Group spacing={17} p={12}>
            <ThemeIcon size={22} color="primary.4" radius="100%">
              <IconExclamationCircle size={14} />
            </ThemeIcon>
            <Text size={14} color="dark.5" weight={600}>
              Aucun résultat
            </Text>
            <ActionIcon onClick={() => setPlaceholderOpen(false)}>
              <IconX />
            </ActionIcon>
          </Group>
        </LightDrawer>
      ) : (
        <LightDrawer
          opened={!isFilterMatch && outlet != null}
          onClose={() => navigate('..', { replace: true, relative: 'path' })}
          width={500}
          bg="transparent"
          bottom={110}
          shadow="none">
          {outlet}
        </LightDrawer>
      )}
      <FullDrawer
        opened={isFilterMatch && outlet !== null}
        width={530}
        onClose={handleClose}
        scrollAreaComponent={undefined}>
        {outlet}
      </FullDrawer>

      <Box pos="absolute" right={0} bottom={0} p={15} w={500}>
        <Card radius={10} shadow="0 4px 7px rgba(0, 0, 0, 0.15)" bg="complementary.1" p={12} mt={12} withBorder={false}>
          <SimpleGrid cols={2} spacing={12}>
            <Card c="primary.4" shadow="0px 4px 10px rgba(0, 0, 0, 0.05)" radius={10} p={12} withBorder={false}>
              <Group spacing={8} noWrap>
                <Text size={34} weight={700} lh={1.3}>
                  {counters.globalThisYear}
                </Text>
                <Text size={10} weight={600}>
                  Signalements de la communauté en {currentYear()}
                </Text>
              </Group>
            </Card>
            <Card c="primary.4" shadow="0px 4px 10px rgba(0, 0, 0, 0.05)" radius={10} p={12} withBorder={false}>
              <Group spacing={8} noWrap>
                <Text size={34} weight={700} lh={1.3}>
                  {counters.companyThisYear}
                </Text>
                <Text size={10} weight={600}>
                  Signalements de votre société en {currentYear()}
                </Text>
                <ActionIcon size={36} bg="tertiary.2" radius={4} onClick={exportAlertReports} loading={exportLoading}>
                  <IconDownload />
                </ActionIcon>
              </Group>
            </Card>
          </SimpleGrid>
        </Card>
      </Box>
    </Page>
  );
};

const alertReportMapRoute = defineRoute({
  component: AlertReportMap,
  loader,
  actions,
  shouldRevalidate: preventActionLeave<typeof actions>('exportAlertReports'),
});

export default alertReportMapRoute;
