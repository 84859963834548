import React, { FC } from 'react';
import { Label, Legend, Line, LineChart, ReferenceArea, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import CustomLegend from '@shared/modules/charts/line/CustomLegend';
import { useTheme } from '@emotion/react';
import { renderOptional } from '@shared/utils/render';
import * as O from 'fp-ts/Option';
import * as A from 'fp-ts/Array';
import { Charts } from '@shared/modules/charts/model';
import { NumberUtils } from '@shared/utils/number';
import formatPercent = NumberUtils.formatPercent;
import { DateFormat, formatDate } from '@shared/modules/dates';
import CustomTooltip from '@shared/modules/charts/line/CustomTooltip';
import roundWithDigits = NumberUtils.roundWithDigits;

interface ReferenceAreaProps {
  min: number;
  max: number;
}

interface HumidityLineChartProps {
  data: O.Option<Array<{ date: Date; soilHumidity: number }>>;
  referenceArea: O.Option<ReferenceAreaProps>;
  dateFormatter(date: Date): string;
}

const defaultMargin = { bottom: 0, left: 0, right: 0, top: 30 };
const defaultYAxisWidth = 25;
const yTicks = A.makeBy(11, i => i * 0.1);

const HumidityLineChart: FC<HumidityLineChartProps> = ({ data, dateFormatter, referenceArea }) => {
  const theme = useTheme();
  const dataKey: Charts.Line.Humidity.DataKey = 'soilHumidity';

  return (
    <ResponsiveContainer height={260}>
      <LineChart data={O.toUndefined(data)} margin={defaultMargin}>
        <XAxis
          fontSize={8}
          color={theme.colors.dark[0]}
          dataKey="date"
          type="category"
          tickLine={false}
          tickFormatter={dateFormatter}
        />
        <YAxis
          fontSize={8}
          color={theme.colors.dark[0]}
          width={defaultYAxisWidth}
          tickLine={false}
          axisLine={false}
          interval="preserveStartEnd"
          ticks={yTicks}
          tickFormatter={value => `${roundWithDigits(value * 100, 0)}`}>
          <Label value="En décimale" fontSize={8} offset={15} position="top" dx={defaultYAxisWidth / 2} />
        </YAxis>
        <Tooltip
          formatter={value => formatPercent(value as number)}
          labelFormatter={date => formatDate(date, DateFormat.LocalDateTime)}
          separator=" "
          content={CustomTooltip}
        />
        <Legend content={CustomLegend} />
        <Line
          type="step"
          dataKey={dataKey}
          name={Charts.Line.Humidity.dataKeyLabel[dataKey]}
          stroke={theme.colors.blue[6]}
          strokeWidth={2}
          dot={false}
        />
        {renderOptional(referenceArea, ({ min, max }) => (
          <ReferenceArea
            y1={min}
            y2={max}
            fill={theme.colors.secondary[1]}
            fillOpacity={0.3}
            stroke={theme.colors.gray[2]}
            strokeDasharray={3}
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default HumidityLineChart;
