import React, { FC } from 'react';
import { Card, Stack, Text } from '@mantine/core';
import * as O from 'fp-ts/Option';
import { Measures } from '@shared/modules/measures/model';
import RealTimeMeasure = Measures.RealTimeMeasure;
import { pipe } from 'fp-ts/function';
import SignalStrength = Measures.SignalStrength;

interface ActiveSensorSignalInfoProps {
  signal: O.Option<RealTimeMeasure.Impl<Measures.Type.Signal>>;
}

const ActiveSensorSignalInfo: FC<ActiveSensorSignalInfoProps> = ({ signal }) => {
  return pipe(
    signal,
    O.chainNullableK(({ value }) => {
      switch (value) {
        case SignalStrength.Poor:
          return 'Un signal de faible intensité peut entrainer la perte des données dans les graphiques';
        default:
          return null;
      }
    }),
    O.map(info => (
      <Card
        key="info"
        px={13}
        py={10}
        withBorder={false}
        radius={8}
        sx={theme => ({ border: `1px solid ${theme.colors.tertiary[2]}` })}>
        <Stack spacing={6}>
          <Text color="dark.1" size={10} weight={600}>
            Information
          </Text>
          <Text size={12}>{info}</Text>
        </Stack>
      </Card>
    )),
    O.toNullable,
  );
};

export default ActiveSensorSignalInfo;
