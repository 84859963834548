import React, { FC } from 'react';
import * as Styled from './Nav.styles';
import { Indicator, Text, ThemeIcon, Tooltip } from '@mantine/core';
import {
  IconBell,
  IconChartBar,
  IconDashboard,
  IconHome,
  IconMapPin,
  IconSeeding,
  TablerIconsProps,
} from '@tabler/icons-react';
import { renderNullable } from '@shared/utils/render';

import logo from '@assets/logos/logo-horizontal.svg';
import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import * as D from 'fp-ts/Date';
import { LayoutContext } from '@layout/model';
import { To } from 'react-router-dom';
import { stringifyQueries } from '@shared/utils/queries';
import { AlertReport } from '@modules/alert-reports/model';
import { DateFormat, formatDate } from '@shared/modules/dates';

interface NavEntry {
  title: string;
  to: To;
  icon: FC<TablerIconsProps>;
  counter?: (ctx: LayoutContext) => number;
  end?: boolean;
}

interface NavGroup {
  title?: string;
  entries: Array<NavEntry>;
}

const currentYear = formatDate(D.create(), DateFormat.Year);

const entries: Array<NavGroup> = [
  {
    entries: [{ title: 'Accueil', to: '/', icon: IconHome }],
  },
  {
    title: 'Objets connectées',
    entries: [
      { title: 'Tableau de bord Sonde(s)', to: '/iot', icon: IconDashboard, end: true },
      {
        title: 'Parc de sondes',
        to: '/iot/sensors',
        icon: IconSeeding,
        counter: ({ pendingSensorCount }) => pendingSensorCount,
      },
      {
        title: 'Sondes HYDRASOLO',
        to: '/iot/hydrasolo',
        icon: IconSeeding,
      },
      { title: 'Gestion des alertes', to: '/iot/alert-triggers', icon: IconBell },
    ],
  },
  {
    title: 'Épidémio surveillance',
    entries: [
      {
        title: 'Tableau de bord Bioagresseurs',
        to: '/alert-reports',
        icon: IconDashboard,
        end: true,
      },
      {
        title: 'Carte Signalements',
        to: {
          pathname: '/alert-reports/map',
          search: stringifyQueries<Partial<AlertReport.Filter>>(AlertReport.defaultFilter),
        },
        icon: IconMapPin,
      },
      {
        title: 'Analyse de mes signalements',
        to: {
          pathname: '/alert-reports/analysis',
          search: stringifyQueries<Partial<AlertReport.Filter>>({ year: currentYear }),
        },
        icon: IconChartBar,
      },
    ],
  },
];

interface NavEntryProps {
  entry: NavEntry;
  context: LayoutContext;
  navOpen: boolean;
}

const NavEntryComponent: FC<NavEntryProps> = ({ entry, context, navOpen }) => {
  const counter = pipe(
    O.fromNullable(entry.counter),
    O.map(counter => counter(context)),
    O.filter(value => value > 0),
    O.toNullable,
  );

  const Icon = entry.icon;

  return (
    <Styled.NavEntry to={entry.to} end={entry.end} $compact={!navOpen}>
      <Tooltip label={entry.title} withinPortal disabled={navOpen} position="right">
        <Indicator disabled={navOpen || !counter} color="tertiary.5">
          <ThemeIcon size={26} variant="light">
            <Icon size={14} />
          </ThemeIcon>
        </Indicator>
      </Tooltip>

      <Text component="span" fz={12} fw={600} c="dark.3">
        {entry.title}
      </Text>

      {counter ? <Styled.NavEntryCounter>{counter}</Styled.NavEntryCounter> : null}
    </Styled.NavEntry>
  );
};

interface NavProps {
  open: boolean;
  context: LayoutContext;
}

const Nav: FC<NavProps> = ({ open, context }) => {
  return (
    <Styled.NavContainer $open={open}>
      <Styled.NavHeader>
        <div>
          <img width={139} height={34} src={logo} alt="Logo" />
        </div>
      </Styled.NavHeader>

      <Styled.NavContentWrapper>
        <div>
          <div>
            <Styled.NavContent>
              {entries.map((group, i) => (
                <Styled.NavGroup key={i}>
                  {renderNullable(group.title, title => (
                    <Styled.NavGroupTitle size={10} c="dark.0" tt="uppercase" fw={600} $hidden={!open}>
                      {title}
                    </Styled.NavGroupTitle>
                  ))}

                  {group.entries.map((entry, i) => (
                    <NavEntryComponent key={i} entry={entry} context={context} navOpen={open} />
                  ))}
                </Styled.NavGroup>
              ))}
            </Styled.NavContent>
          </div>
        </div>
      </Styled.NavContentWrapper>
    </Styled.NavContainer>
  );
};

export default Nav;
