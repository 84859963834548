import React, { FC } from 'react';
import { Box, MantineColor, Text, Tooltip } from '@mantine/core';
import { Cell, Pie, PieChart, ResponsiveContainer, Sector } from 'recharts';
import { NumberUtils } from '@shared/utils/number';
import { useTheme } from '@emotion/react';
import { useDisclosure } from '@mantine/hooks';
import { BoxProps } from '@mantine/core/lib/Box/Box';
import formatPercent = NumberUtils.formatPercent;

const PADDING_ANGLE = 8;

const ActiveShape = ({ outerRadius, innerRadius, color, endAngle, value, ...props }: any) => {
  const theme = useTheme();
  return (
    <Tooltip label={formatPercent(value)} withinPortal>
      <g>
        <Sector {...props} innerRadius={innerRadius} outerRadius={outerRadius} endAngle={endAngle} />
        <Sector
          {...props}
          innerRadius={innerRadius - 18}
          outerRadius={innerRadius - 14}
          fill={theme.colors.gray[4]}
          endAngle={endAngle - PADDING_ANGLE}
        />
      </g>
    </Tooltip>
  );
};

interface AlertsPieChartData {
  value: number;
  bg: MantineColor;
  active: boolean;
}

interface AlertsPieChartProps {
  data: Array<AlertsPieChartData>;
  value: number;
  mah: number;
}

const AlertsPieChart: FC<AlertsPieChartProps & BoxProps> = ({ data, value, mah, ...rest }) => {
  const [mounted, handlers] = useDisclosure(false);
  const theme = useTheme();
  const activeIndexes = data.reduce<Array<number>>((acc, curr, index) => (curr.active ? [...acc, index] : acc), []);

  return (
    <Box mah={mah} h="100%" style={{ overflow: 'hidden' }} {...rest}>
      <Box pos="relative" h="200%">
        <ResponsiveContainer>
          <PieChart>
            <Pie
              data={data}
              cy="50%"
              cx="50%"
              innerRadius="75%"
              outerRadius="100%"
              startAngle={180}
              endAngle={0}
              paddingAngle={PADDING_ANGLE}
              labelLine={false}
              dataKey="value"
              onAnimationEnd={handlers.open}
              animationDuration={0}
              activeShape={<ActiveShape />}
              activeIndex={activeIndexes}>
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={theme.fn.themeColor(entry.bg)} strokeWidth={0} />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>

        {mounted && (
          <Box pos="absolute" bottom="50%" left="50%" ta="center" style={{ transform: 'translate(-50%, -10px)' }}>
            <Text size={34} color="dark.5" weight={700} pl={5}>
              {NumberUtils.roundWithDigits(value * 100, 2)}
              <Text component="span" size={12} sx={{ whiteSpace: 'nowrap' }}>
                {' '}
                %
              </Text>
            </Text>
            <Text tt="uppercase" color="dark.2" size={10} weight={600}>
              Taux d'alertes
            </Text>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default AlertsPieChart;
