import React, { createElement, FC } from 'react';
import { Anchor, Box, SimpleGrid, Text } from '@mantine/core';
import { Editorial } from '@shared/modules/editorial/model';
import Block = Editorial.Block;
import Template = Editorial.Block.Template;
import Contact = Block.Contact;
import { Icon, IconMailFilled, IconPhoneFilled } from '@tabler/icons-react';
import { renderOptional } from '@shared/utils/render';
import { filterEmptyStringToOption } from '@shared/utils/string';

interface ContactRendererProps {
  block: Block<Template.Contact>;
}

const linkBuilder: Record<Block.Contact.Type, (target: string) => string> = {
  [Contact.Type.Phone]: target => `tel:${target}`,
  [Contact.Type.Mail]: target => `mailto:${target}`,
};

const ContactIcon: Record<Block.Contact.Type, Icon> = {
  [Contact.Type.Phone]: IconPhoneFilled,
  [Contact.Type.Mail]: IconMailFilled,
};

const ContactRenderer: FC<ContactRendererProps> = ({ block }) => {
  const href = linkBuilder[block.type](block.target);

  return (
    <Box py={14}>
      <SimpleGrid
        py={10}
        px={7}
        style={{ gridTemplateColumns: '35px 1fr', alignItems: 'center', columnGap: 12, rowGap: 0 }}
        bg="primary"
        c="white">
        {createElement(ContactIcon[block.type], { size: 30, style: { gridRow: 'span 2' } })}
        <Text opacity={0.5}>{block.text}</Text>
        <Anchor c="white" href={href}>
          {block.target}
        </Anchor>
      </SimpleGrid>

      {renderOptional(filterEmptyStringToOption(block.content), content => (
        <Box
          ml={14}
          px={14}
          py={7}
          bg="#eee"
          style={{ borderLeft: '2px solid #e64b3c' }}
          dangerouslySetInnerHTML={{ __html: content }}
        />
      ))}
    </Box>
  );
};

export default ContactRenderer;
