import React, { FC, ReactNode } from 'react';
import { ActionIcon, Badge, Box, Card, Group, Stack, Text } from '@mantine/core';
import { IconEye } from '@tabler/icons-react';
import { Link } from 'react-router-dom';

import PestDateRange from '@modules/alert-reports/pests/components/PestDateRange';
import PestStage from '@modules/alert-reports/pests/components/PestStage';
import { Dashboard } from '@modules/dashboard/model';
import { renderOptional } from '@shared/utils/render';

import * as A from 'fp-ts/Array';
import { DashboardUtils } from '@modules/dashboard/utils';

interface DashboardPestProps {
  pest: Dashboard.Pest;
}

const DashboardPest: FC<DashboardPestProps> = ({ pest }) => {
  const risks = DashboardUtils.Pest.mergeRisks(pest.risks);

  return (
    <Box pos="relative" h="100%">
      <Badge
        pos="absolute"
        top={0}
        right={40}
        px={10}
        py={3}
        bg="primary"
        radius={100}
        fw={700}
        fz={14}
        lh={1.55}
        c="white"
        h="auto"
        style={{ transform: 'translateY(-50%)', zIndex: 10 }}>
        NEW
      </Badge>
      <Card
        pos="absolute"
        top={0}
        left={0}
        w="100%"
        mah="100%"
        withBorder={false}
        radius={10}
        shadow="0px 0px 20px 0px rgba(250, 82, 82, 0.40)"
        px={20}
        py={15}
        style={{
          overflow: 'auto',
        }}>
        <PestDateRange dateRange={pest.dateRange} pt={5} pb={4} />
        {renderOptional<Array<DashboardUtils.Pest.MergedRisk>, ReactNode>(
          risks,
          A.mapWithIndex((index, risk) => (
            <Stack key={index} spacing={5} py={15} sx={{ '&:not(:last-child)': { borderBottom: `1px solid #DADADA` } }}>
              <Group position="apart" noWrap align="start">
                <Box>
                  <Text c="black" fw={700} fz={16} lh={1.55} mb={5}>
                    {risk.name}
                  </Text>
                  <Stack spacing={5}>
                    {risk.stages.map(({ stage, level }) => (
                      <PestStage key={stage} name={stage} level={level} />
                    ))}
                  </Stack>
                </Box>

                <ActionIcon
                  component={Link}
                  to={`/alert-reports/pests/${risk.enum}`}
                  color="tertiary.5"
                  bg="tertiary.1"
                  size={30}
                  style={{ justifySelf: 'right' }}>
                  <IconEye strokeWidth={1} size={20} />
                </ActionIcon>
              </Group>
            </Stack>
          )),
          () => (
            <Text mt={10} pb={5}>
              Aucun risque de prévu pour cette semaine
            </Text>
          ),
        )}
      </Card>
    </Box>
  );
};

export default DashboardPest;
