import React, { FC, PropsWithChildren } from 'react';
import { Box } from '@mantine/core';

export const ModalContent: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Box
      sx={{
        padding: '15px 20px',
        margin: '0 -20px 20px',
        borderTop: '1px solid #ddd',
        borderBottom: '1px solid #ddd',
      }}>
      {children}
    </Box>
  );
};
