import React, { FC } from 'react';
import { Box, Card, Text } from '@mantine/core';
import { TooltipProps } from 'recharts/types/component/Tooltip';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';
import { sequenceS } from 'fp-ts/Apply';
import * as O from 'fp-ts/Option';
import { pipe } from 'fp-ts/function';

const CustomTooltip: FC<TooltipProps<ValueType, NameType>> = ({
  payload,
  label,
  labelFormatter,
  formatter,
  separator,
}) => {
  return (
    <Card
      shadow="0px 4px 10px rgba(0, 0, 0, 0.05)"
      radius={10}
      p={20}
      sx={theme => ({ '&[data-with-border]': { border: `1px solid ${theme.colors.tertiary[2]}` } })}>
      <Text color="dark.0" size={10} pb={4}>
        {labelFormatter?.(label, payload ?? []) ?? label}
      </Text>
      <Box component="ul" m={0} p={0}>
        {payload?.map(({ name, value, payload: item, color }, index) => (
          <Text
            component="li"
            color="tertiary.7"
            size={14}
            weight={600}
            key={index}
            display="flex"
            sx={{
              alignItems: 'center',
              listStyle: 'none',
              ':before': {
                content: '""',
                display: 'inline-block',
                width: 8,
                height: 8,
                background: color,
                borderRadius: 8,
                marginRight: 6,
              },
            }}>
            {[
              name,
              pipe(
                sequenceS(O.Apply)({
                  formatter: O.fromNullable(formatter),
                  value: O.fromNullable(value),
                  name: O.fromNullable(name),
                }),
                O.fold(
                  () => value,
                  ({ formatter, value, name }) => formatter(value, name, item, index, payload),
                ),
              ),
            ].join(separator)}
          </Text>
        ))}
      </Box>
    </Card>
  );
};

export default CustomTooltip;
