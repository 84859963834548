import React, { FC } from 'react';
import { ActionIcon, Badge, Box, Card, Group, SimpleGrid, Text } from '@mantine/core';
import { IconEye } from '@tabler/icons-react';
import { Link } from 'react-router-dom';
import * as NEA from 'fp-ts/NonEmptyArray';

import { AlertReport } from '@modules/alert-reports/model';
import { renderNullable } from '@shared/utils/render';
import { Measures } from '@shared/modules/measures/model';
import SensorsDataListMeasure from '@modules/alert-reports/analysis/components/sensors/SensorsDataListMeasure';
import { Sensor } from '@modules/iot/sensors/model';

interface SensorsDataListProps {
  sensors: NEA.NonEmptyArray<AlertReport.Sensor>;
}

const SensorsDataList: FC<SensorsDataListProps> = ({ sensors }) => {
  return (
    <Card radius={10} shadow="0px 4px 10px 0px rgba(0, 0, 0, 0.10)" withBorder={false} p={20}>
      <SimpleGrid cols={4} c="dark.1" fw={600} lh={1.55} fz={10} pt={14} pb={28}>
        <Text>Sonde</Text>
        <Text>Humidité</Text>
        <Text>Température</Text>
        <Text>Humidité foliaire</Text>
      </SimpleGrid>
      {sensors.map(({ id, technicalId, type, serial, canAccessDetail, zone, measures }) => (
        <SimpleGrid
          key={technicalId}
          cols={4}
          py={8}
          sx={({ colors }) => ({
            borderTop: `1px solid ${colors.gray[1]}`,
          })}>
          <Box>
            {renderNullable(zone, zone => (
              <Badge h="auto" radius={13} px={17} py={3} bg="complementary.3" c="primary" lh={1.3} fz={12} fw={600}>
                {zone}
              </Badge>
            ))}

            <Text mt={2} fz={14} lh={1.55} c="dark.5" fw={600}>
              {serial}
            </Text>
          </Box>
          <SensorsDataListMeasure
            measures={measures}
            type={Measures.Type.Humidity}
            probes={[
              Sensor.Probe.Identifier.Ground,
              Sensor.Probe.Identifier.Ground1,
              Sensor.Probe.Identifier.Ground2,
              Sensor.Probe.Identifier.Transmitter,
            ]}
          />
          <SensorsDataListMeasure measures={measures} type={Measures.Type.Temperature} />

          <Group position="apart">
            <SensorsDataListMeasure
              measures={measures}
              type={Measures.Type.Humidity}
              probes={[Sensor.Probe.Identifier.Leaf]}
              mb="auto"
            />
            {canAccessDetail && (
              <ActionIcon
                component={Link}
                to={`/iot/sensors/list/${type}/${id}`}
                size={36}
                bg="tertiary.2"
                radius={4}
                style={{ justifySelf: 'right' }}>
                <IconEye strokeWidth={1.2} />
              </ActionIcon>
            )}
          </Group>
        </SimpleGrid>
      ))}
    </Card>
  );
};

export default SensorsDataList;
