import React, { FC, PropsWithChildren } from 'react';
import { Card, Image, Text } from '@mantine/core';
import placeholder from '@assets/icons/alert-reports/diseases-data-placeholder.svg';

const DiseasesDataPlaceholder: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Card
      px={42}
      py={24}
      maw={705}
      mx="auto"
      radius={10}
      sx={theme => ({ '&[data-with-border]': { border: `2px solid ${theme.colors.tertiary[3]}` } })}>
      <Text c="tertiary.4" fz={26} fw={700} lh={1.35} ta="center">
        {children}
      </Text>
      <Image src={placeholder} maw={264} mx="auto" />
    </Card>
  );
};

export default DiseasesDataPlaceholder;
