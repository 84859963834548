import React, { FC } from 'react';
import { defineLoader, httpTaskToResponseTask, useLoader } from '@core/router/loader';
import { defineRoute } from '@core/router';
import DrawerContent from '@shared/components/drawer/DrawerContent';
import { Anchor, Button, Group, Title } from '@mantine/core';
import { Link, useNavigate } from 'react-router-dom';
import Seo from '@shared/modules/seo/Seo';
import { defineAction, useAction } from '@core/router/action';
import { AlertTrigger } from '@modules/iot/alert-triggers/model';
import { AlertTriggersService } from '@modules/iot/alert-triggers/service';
import { SensorsService } from '@modules/iot/sensors/service';
import { ActiveSensor } from '@modules/iot/sensors/model';
import { EnhancedForm, useEnhancedForm } from '@shared/modules/form';
import { zodResolver } from '@hookform/resolvers/zod';
import { pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';
import CreateAlertTriggerForm from '@modules/iot/alert-triggers/components/create/CreateAlertTriggerForm';

const loader = defineLoader({
  handler: () => httpTaskToResponseTask(SensorsService.getActiveSensors(ActiveSensor.defaultFilter)),
});

const actions = {
  create: defineAction({
    type: 'create',
    payload: AlertTrigger.CreateParams,
    handler: ({ payload }) => AlertTriggersService.createAlertTrigger(payload),
    flashOptions: {
      success: () => 'Création réalisée',
    },
  }),
};

const defaultValues: Partial<AlertTrigger.CreateParams> = {
  notification: {
    byEmail: false,
    byPush: true,
  },
};

const CreateAlertTriggerPage: FC = () => {
  const sensors = useLoader<typeof loader>();

  const navigate = useNavigate();

  const { formRef, form, handleFormSubmit } = useEnhancedForm<AlertTrigger.CreateParams>({
    resolver: zodResolver(AlertTrigger.CreateParams),
    defaultValues,
  });

  const [loading, create] = useAction(actions.create);

  const handleCreateAlertTrigger = (params: AlertTrigger.CreateParams) =>
    pipe(
      create(params),
      TE.chainIOK(() => () => navigate('..', { replace: true })),
    );

  return (
    <DrawerContent
      top={<Title size="h3">Créer une alerte</Title>}
      bottom={
        <Group position="right">
          <Anchor component={Link} to=".." replace size="sm" fw={600} c="dark.3">
            Annuler
          </Anchor>
          <Button loading={loading} disabled={loading} onClick={handleFormSubmit}>
            Créer
          </Button>
        </Group>
      }>
      <Seo title="Créer une alerte" />

      <EnhancedForm ref={formRef} form={form} onSubmit={handleCreateAlertTrigger} preventLeave>
        <CreateAlertTriggerForm sensors={sensors} />
      </EnhancedForm>
    </DrawerContent>
  );
};

const createAlertTriggerRoute = defineRoute({
  component: CreateAlertTriggerPage,
  loader,
  actions,
});

export default createAlertTriggerRoute;
