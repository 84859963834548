import React, { FC } from 'react';
import { useEnhancedFormContext } from '@shared/modules/form';
import { AlertReport } from '@modules/alert-reports/model';
import { Checkbox, Chip, Divider, Group, Radio, Select, SimpleGrid, Stack, Text, TextInput } from '@mantine/core';
import { Controller } from 'react-hook-form';
import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import * as A from 'fp-ts/Array';
import * as NEA from 'fp-ts/NonEmptyArray';
import * as R from 'fp-ts/Record';
import * as Ord from 'fp-ts/Ord';
import { DatePickerInput } from '@mantine/dates';
import { DateFormat, formatDate, parseDate } from '@shared/modules/dates';
import { DatesRangeValue } from '@mantine/dates/lib/types/DatePickerValue';
import { useFetchTaskList } from '@core/http/hooks';
import { AlertReportsService } from '@modules/alert-reports/service';
import { AlertReportUtils } from '@modules/alert-reports/utils';
import { AlertReportSchema } from '@modules/alert-reports/schema';

const EMPTY_VAlUE = 'empty';

const FilterForm: FC = () => {
  const {
    register,
    watch,
    control,
    setValue,
    formState: { errors },
  } = useEnhancedFormContext<AlertReport.Filter.SaveParams>();
  const categoryId = watch('categoryId');
  const subCategoryId = watch('subCategoryId');

  const [categories] = useFetchTaskList(AlertReportsService.Referential.getCategories);
  const [subcategories] = useFetchTaskList(AlertReportUtils.getNullableSubCategories, categoryId);
  const [types] = useFetchTaskList(AlertReportUtils.getNullableTypes, subCategoryId);

  const period = watch('period');

  const gravityData = pipe(
    O.fromNullable(subCategoryId),
    O.chain(id =>
      pipe(
        subcategories,
        A.findFirst(sub => sub.id === id),
      ),
    ),
    O.fold(
      () => [],
      ({ maxGravity }) =>
        pipe(
          NEA.range(0, maxGravity),
          A.map(gravity => ({ value: `${gravity}`, label: `${gravity}` })),
        ),
    ),
  );

  const handleDatesChange = ([start, end]: DatesRangeValue) => {
    setValue('personalizedStartDate', formatDate(start, DateFormat.LocalDateTime));
    setValue('personalizedEndDate', formatDate(end, DateFormat.LocalDateTime));
  };

  const handleStatusChange = (status: AlertReport.Status | typeof EMPTY_VAlUE) =>
    setValue('status', status === EMPTY_VAlUE ? null : status);

  const handlePeriodChange = (period: AlertReport.Period | typeof EMPTY_VAlUE) => {
    setValue('period', period === EMPTY_VAlUE ? null : period);
    setValue('personalizedStartDate', null);
    setValue('personalizedEndDate', null);
  };

  const handleCategoryChange = () => {
    setValue('subCategoryId', null);
    setValue('typeId', null);
    setValue('gravity', null);
  };
  const handleSubCategoryChange = (subCategoryId: `${AlertReport.SubCategoryId}` | null) => {
    setValue('typeId', null);
    setValue('gravity', null);
    setValue('subCategoryId', AlertReportSchema.filterSchema.shape.subCategoryId.parse(subCategoryId));
  };

  return (
    <form>
      <Stack spacing={12}>
        <TextInput
          {...register('name')}
          label="Nom du filtre"
          placeholder="Nom du filtre"
          error={!!errors.name}
          required
        />
        <SimpleGrid cols={2}>
          <Controller
            name="categoryId"
            control={control}
            rules={{ onChange: handleCategoryChange }}
            render={({ field }) => (
              <Select
                label="Catégorie"
                placeholder="Sélectionner"
                data={categories.map(({ id, label }) => ({ value: `${id}`, label }))}
                {...field}
                value={field.value ? `${field.value}` : null}
                error={!!errors.categoryId}
                clearable
              />
            )}
          />
          <Controller
            name="subCategoryId"
            control={control}
            render={({ field }) => (
              <Select
                label="Sous catégorie"
                placeholder="Sélectionner"
                data={subcategories.map(({ id, label }) => ({ value: `${id}`, label }))}
                {...field}
                onChange={handleSubCategoryChange}
                value={field.value ? `${field.value}` : null}
                disabled={!categoryId}
                error={!!errors.subCategoryId}
                clearable
              />
            )}
          />
        </SimpleGrid>
        <Controller
          name="typeId"
          control={control}
          render={({ field }) => (
            <Select
              label="Type de signalement"
              placeholder="Sélectionner"
              data={types.map(({ id, label }) => ({ value: `${id}`, label }))}
              {...field}
              disabled={!subCategoryId}
              value={field.value ? `${field.value}` : null}
              error={!!errors.typeId}
              clearable
            />
          )}
        />
        <Controller
          name="gravity"
          control={control}
          render={({ field }) => (
            <Select
              label="Niveau de gravité"
              placeholder="Sélectionner"
              data={gravityData}
              {...field}
              value={field.value !== null ? `${field.value}` : null}
              disabled={!subCategoryId}
              error={!!errors.gravity}
              clearable
            />
          )}
        />
        <Divider my={8} color="gray.3" />
        <Text color="dark.5" size={12} weight={600} lh={1.55}>
          Période
        </Text>
        <Controller
          control={control}
          name="period"
          render={({ field }) => (
            <Chip.Group {...field} value={field.value ?? EMPTY_VAlUE} onChange={handlePeriodChange}>
              <Group spacing={10}>
                <Chip value={EMPTY_VAlUE}>Aucun</Chip>
                {pipe(
                  R.toEntries(AlertReport.periodLabel),
                  A.sort(Ord.tuple(AlertReport.periodOrd, Ord.trivial)),
                  A.map(([period, label]) => (
                    <Chip key={period} value={period}>
                      {label}
                    </Chip>
                  )),
                )}
              </Group>
            </Chip.Group>
          )}
        />

        {period === AlertReport.Period.Personalized && (
          <DatePickerInput
            type="range"
            placeholder="Sélectionner"
            allowSingleDateInRange
            mt={8}
            onChange={handleDatesChange}
            value={[
              parseDate(watch('personalizedStartDate'), DateFormat.LocalDateTime, null),
              parseDate(watch('personalizedEndDate'), DateFormat.LocalDateTime, null),
            ]}
            error={!!errors.personalizedStartDate || !!errors.personalizedEndDate}
          />
        )}

        <Divider my={8} color="gray.3" />
        <Group spacing={20}>
          <Checkbox
            {...register('myReports')}
            error={!!errors.myReports}
            label={
              <Text weight={600} color="dark.5" size={14} lh={1.55}>
                Mes signalements
              </Text>
            }
          />

          <Checkbox
            {...register('hasPictures')}
            error={!!errors.hasPictures}
            label={
              <Text weight={600} color="dark.5" size={14} lh={1.55}>
                Avec photos
              </Text>
            }
          />
          <Checkbox
            {...register('privateOnly')}
            error={!!errors.privateOnly}
            label={
              <Text weight={600} color="dark.5" size={14} lh={1.55}>
                Uniquement privé
              </Text>
            }
          />
        </Group>
        <Divider my={8} color="gray.3" />

        <Controller
          control={control}
          name="status"
          render={({ field }) => (
            <Radio.Group
              label="Statut"
              {...field}
              onChange={handleStatusChange}
              value={field.value ?? EMPTY_VAlUE}
              error={!!errors.status}
              styles={theme => ({
                label: {
                  fontSize: 12,
                  fontWeight: 600,
                  lineHeight: 1.55,
                  color: theme.colors.dark[5],
                  paddingBottom: 10,
                },
              })}>
              <Group spacing={28}>
                <Radio value={EMPTY_VAlUE} label="Tous" />
                {pipe(
                  R.toEntries(AlertReport.statusLabel),
                  A.map(([status, label]) => <Radio key={status} value={status} label={label} />),
                )}
              </Group>
            </Radio.Group>
          )}
        />
      </Stack>
    </form>
  );
};

export default FilterForm;
