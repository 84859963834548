import React, { FC, useState } from 'react';
import { defineRoute, useRestrictedProfile } from '@core/router';
import { defineLoader, httpTaskToResponseTask, useLoader } from '@core/router/loader';

import Page, { PageProps } from '@layout/page/Page';
import { Range, useVirtualizedTableColumns, VirtualizedList } from '@shared/modules/range';
import { constVoid, pipe } from 'fp-ts/function';
import { ActionIcon, Anchor, Badge, Box, Button, Group, Tooltip } from '@mantine/core';
import { UsersService } from '@modules/users/service';
import { User } from '@modules/users/model';
import * as T from 'fp-ts/Task';
import { Link, useNavigate, useOutlet } from 'react-router-dom';
import { IconPencil, IconSend } from '@tabler/icons-react';
import FullDrawer from '@shared/components/drawer/FullDrawer';
import { defineAction, useAction } from '@core/router/action';
import ActivationState = User.ActivationState;

const loader = defineLoader({
  handler: () => httpTaskToResponseTask(UsersService.getUsers()),
});

const actions = {
  triggerActivation: defineAction({
    type: 'activation',
    payload: User.Id,
    handler: ({ payload }) => UsersService.triggerUserActivation(payload),
    flashOptions: {
      success: () => 'Invitation envoyée',
    },
  }),
};

const UsersListPage: FC = () => {
  const profile = useRestrictedProfile(User.Role.Admin);

  const users = useLoader<typeof loader>();

  const outlet = useOutlet();
  const navigate = useNavigate();

  const [loadedActivation, setLoadedActivation] = useState<User.Id | null>(null);

  const [, triggerActivation] = useAction(actions.triggerActivation);

  const handleTriggerActivation = (userId: User.Id) => () => {
    pipe(
      T.fromIO(() => setLoadedActivation(userId)),
      T.chain(() => triggerActivation(userId)),
      T.chainIOK(() => () => setLoadedActivation(null)),
    )();
  };

  const handleCloseDrawer = () => navigate('.', { replace: true });

  const pageProps: PageProps = {
    top: 'Gestion des utilisateurs',
  };

  const { header, row } = useVirtualizedTableColumns<User>([
    {
      key: 'lastname',
      label: 'Nom',
    },
    {
      key: 'firstname',
      label: 'Prénom',
    },
    {
      key: 'email',
      label: 'E-mail',
      width: '3fr',
      renderer: ({ email }) => <Anchor href={`mailto:${email}`}>{email}</Anchor>,
    },
    {
      key: 'role',
      label: 'Type',
      renderer: ({ role }) => User.roleLabel[role],
    },
    {
      key: 'state',
      label: 'Statut',
      width: '100px',
      renderer: ({ state }) => (
        <Badge color={User.ActivationState.Activated === state ? 'green' : 'orange'}>
          {User.activateStateLabel[state]}
        </Badge>
      ),
    },
    {
      key: 'actions',
      width: '180px',
      label: profile.rights.createTech ? (
        <Button component={Link} to="new" disabled={!profile.rights.createTech}>
          Inviter un technicien
        </Button>
      ) : null,
      headerStyle: { textAlign: 'right' },
      renderer: ({ id, state }) => (
        <Group position="right" spacing="sm">
          {state === ActivationState.Pending ? (
            <Tooltip label="Renvoyer un mail d'activation">
              <ActionIcon
                variant="light"
                c="tertiary"
                size={36}
                loading={id === loadedActivation}
                disabled={id === loadedActivation}
                onClick={handleTriggerActivation(id)}>
                <IconSend size={20} />
              </ActionIcon>
            </Tooltip>
          ) : null}

          <Tooltip label="Modifier">
            <ActionIcon component={Link} to={`${id}`} c="tertiary" variant="light" size={36}>
              <IconPencil size={20} />
            </ActionIcon>
          </Tooltip>
        </Group>
      ),
    },
  ]);

  return (
    <Page {...pageProps}>
      <Box p={30}>
        <VirtualizedList header={header} rowHeight={67} range={Range.fromArray(users, {})} loadPage={T.of(constVoid)}>
          {row}
        </VirtualizedList>
      </Box>

      <FullDrawer opened={outlet != null} onClose={handleCloseDrawer} width={530}>
        {outlet}
      </FullDrawer>
    </Page>
  );
};

const usersListRoute = defineRoute({
  component: UsersListPage,
  loader,
  actions,
});

export default usersListRoute;
