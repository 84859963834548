import React, { FC } from 'react';
import { Editorial } from '@shared/modules/editorial/model';
import Block = Editorial.Block;
import Template = Editorial.Block.Template;
import * as O from 'fp-ts/Option';
import { ResourceUtils } from '@shared/modules/resources/utils';
import getResourceFromDetail = ResourceUtils.getResourceFromDetail;
import { filterEmptyStringToOption } from '@shared/utils/string';
import { renderOptional } from '@shared/utils/render';
import { Anchor, Text } from '@mantine/core';
import { IconArrowRight } from '@tabler/icons-react';
import { EditorialHooks } from '@shared/modules/editorial/hooks';
import useMissingResourceLogger = EditorialHooks.useMissingResourceLogger;

interface PdfRendererProps {
  block: Block<Template.Pdf>;
}

const PdfRenderer: FC<PdfRendererProps> = ({ block }) => {
  const resource = getResourceFromDetail(block.pdf);

  useMissingResourceLogger(block, O.isNone(resource));

  return renderOptional(resource, ({ url }) => (
    <Anchor
      display="flex"
      p={10}
      c="primary"
      underline={false}
      href={url.original}
      download
      sx={theme => ({
        transition: 'color 0.15s linear',
        '&:hover': {
          color: theme.fn.darken(theme.colors.primary[5], 0.1),
        },
      })}>
      <IconArrowRight />

      {renderOptional(filterEmptyStringToOption(block.caption), content => (
        <Text ml={3} style={{ wordBreak: 'break-all' }}>
          {content}
        </Text>
      ))}
    </Anchor>
  ));
};

export default PdfRenderer;
