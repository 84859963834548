import React, { FC } from 'react';
import { defineLoader, httpTaskToResponseTask, useLoader } from '@core/router/loader';
import { defineRoute, usePreserveNavigate } from '@core/router';
import Page from '@layout/page/Page';
import { ActionIcon, Badge, Group, SimpleGrid, Text } from '@mantine/core';
import { sequenceS } from 'fp-ts/Apply';
import * as TE from 'fp-ts/TaskEither';
import * as O from 'fp-ts/Option';
import { AlertReportsService } from '@modules/alert-reports/service';
import { parseQueriesFormUrl, stringifyQueries } from '@shared/utils/queries';
import { AlertReportUtils } from '@modules/alert-reports/utils';
import alertReportsFilterParser = AlertReportUtils.alertReportsFilterParser;
import { useRange, VirtualizedList } from '@shared/modules/range';
import { Link, useOutlet } from 'react-router-dom';
import ListPageHeader from '@modules/alert-reports/analysis/components/ListPageHeader';
import { getPageQuery } from '@shared/modules/range/utils';
import { AlertReport } from '@modules/alert-reports/model';
import { DateFormat, formatDate, parseDate } from '@shared/modules/dates';
import { renderNullable, renderOptional } from '@shared/utils/render';
import { IconAdjustments, IconEye } from '@tabler/icons-react';
import { YearPickerInput } from '@mantine/dates';
import { pipe } from 'fp-ts/function';
import FullDrawer from '@shared/components/drawer/FullDrawer';
import AnalysisListingWeather from '@modules/alert-reports/analysis/components/list/AnalysisListingWeather';
import AnalysisListingScoring from '@modules/alert-reports/analysis/components/list/AnalysisListingScoring';

const loader = defineLoader({
  handler: ({ request }) => {
    const queries = parseQueriesFormUrl(request.url);
    const filter = alertReportsFilterParser(parseQueriesFormUrl(request.url));

    return httpTaskToResponseTask(
      sequenceS(TE.ApplyPar)({
        counters: AlertReportsService.getCounters(),
        items: AlertReportsService.Analysis.getRange(getPageQuery(queries), filter),
      }),
    );
  },
});

const ListPage: FC = () => {
  const navigate = usePreserveNavigate();
  const outlet = useOutlet();
  const { counters } = useLoader<typeof loader>();

  const { range, handleLoadPage } = useRange<typeof loader, AlertReport.Range.Item, AlertReport.Filter>(
    ({ items }) => items,
  );

  const currentYear = pipe(
    O.fromNullable(range.filter.year),
    O.flatMap(year => parseDate(year, DateFormat.Year)),
    O.toNullable,
  );

  const handleYearChange = (date: Date | null) =>
    navigate({ search: stringifyQueries({ ...range.filter, year: formatDate(date, DateFormat.Year) }) });

  const handleOpenFilter = () => navigate({ pathname: 'filters' });
  const handleCloseFilter = () => navigate('..', { replace: true, relative: 'path' });

  return (
    <>
      <Page top="Analyse de mes signalements" pl={48} pr={22} pt={35}>
        <Group spacing={10} pb={29}>
          <Text c="dark.5" fz={18} fw={700} lh={1.45}>
            Liste des signalements de ma société
          </Text>
          <Badge bg="primary.4" c="white" px={10} py={4} radius={13} fz={12} lh={1.3} fw={600}>
            {counters.companyThisYear} signalement{counters.companyThisYear > 1 ? 's' : ''}
          </Badge>
        </Group>
        <Group spacing={10} pb={10}>
          <YearPickerInput value={currentYear} onChange={handleYearChange} placeholder="Choisir une année" clearable />
          <ActionIcon c="primary" size={36} variant="default" onClick={handleOpenFilter}>
            <IconAdjustments strokeWidth={1.2} />
          </ActionIcon>
        </Group>
        <VirtualizedList range={range} header={<ListPageHeader />} loadPage={handleLoadPage} rowHeight={110}>
          {({ item, ref, style, index }) => (
            <SimpleGrid
              key={item.id}
              data-index={index}
              ref={ref}
              cols={7}
              px={20}
              h={110}
              sx={({ colors }) => ({
                gridTemplateColumns: '1fr repeat(4, 2fr) 1fr 50px',
                borderTop: `1px solid ${colors.gray[0]}`,
                alignItems: 'center',
              })}
              style={style}>
              <Text c="dark.5" fz={14} fw={400} lh={1.55}>
                {renderOptional(parseDate(item.reportedAt, DateFormat.LocalDateTime), date =>
                  formatDate(date, DateFormat.LocalDate),
                )}
              </Text>
              <Text c="dark.5" fz={14} fw={600} lh={1.55}>
                {item.type}
              </Text>
              <Text c="primary.4" fz={14} fw={600} lh={1.55}>
                {item.category} / {item.subCategory}
              </Text>
              <Group>
                {renderNullable(item.weather, weather => (
                  <AnalysisListingWeather weather={weather} />
                ))}
              </Group>
              <AnalysisListingScoring pest={item.pest} diseases={item.diseases} />
              {item.sensorCount > 0 ? (
                <Text fz={14}>{item.sensorCount} sonde(s)</Text>
              ) : (
                <Text c="dark.1" fz={14} fw={400} lh={1.55}>
                  Aucune
                </Text>
              )}
              <ActionIcon
                component={Link}
                to={`${item.id}`}
                color="tertiary.5"
                bg="tertiary.1"
                size={34}
                style={{ justifySelf: 'right' }}>
                <IconEye strokeWidth={1} />
              </ActionIcon>
            </SimpleGrid>
          )}
        </VirtualizedList>
      </Page>
      <FullDrawer opened={outlet !== null} width={530} onClose={handleCloseFilter} scrollAreaComponent={undefined}>
        {outlet}
      </FullDrawer>
    </>
  );
};

const alertReportAnalysesRoute = defineRoute({
  component: ListPage,
  loader,
});

export default alertReportAnalysesRoute;
