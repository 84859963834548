import React, { FC, useEffect, useMemo, useState } from 'react';
import { ActionIcon, Badge, Group, Indicator, Title, Tooltip } from '@mantine/core';
import { AlertReport } from '@modules/alert-reports/model';
import { useLocation } from 'react-router-dom';
import { AlertReportUtils } from '@modules/alert-reports/utils';
import { parseQueries } from '@shared/utils/queries';
import { pipe } from 'fp-ts/function';
import * as R from 'fp-ts/Record';
import PreserveSearchLink from '@core/router/components/PreserveSearchLink';
import { IconAdjustments } from '@tabler/icons-react';

interface AlertReportHeaderProps {
  markers: Array<AlertReport.Marker>;
}

const AlertReportHeader: FC<AlertReportHeaderProps> = ({ markers }) => {
  const { search } = useLocation();

  const [filters, setFilters] = useState(() => AlertReportUtils.alertReportsFilterParser(parseQueries(search)));

  const hasFilter = useMemo(
    () =>
      !pipe(
        filters,
        R.filterWithIndex((key, value) => key !== 'search' && value != null),
        R.isEmpty,
      ),
    [filters],
  );

  useEffect(() => {
    setFilters(AlertReportUtils.alertReportsFilterParser(parseQueries(search)));
  }, [search]);

  return (
    <Group align="center">
      <Title size="h3">Signalements</Title>

      <Badge color="primary" bg="complementary.4">
        {markers.length === 0 ? 'Aucun' : markers.length} signalement
        {markers.length > 1 ? 's' : ''}
      </Badge>

      <Tooltip label="Filtrer" position="bottom">
        <Indicator color="tertiary.5" disabled={!hasFilter} withBorder size={13}>
          <ActionIcon
            component={PreserveSearchLink}
            size={36}
            variant="outline"
            c="primary.5"
            color="gray.2"
            to="filters">
            <IconAdjustments size={22} strokeWidth={1.3} />
          </ActionIcon>
        </Indicator>
      </Tooltip>
    </Group>
  );
};

export default AlertReportHeader;
