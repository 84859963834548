import { FC } from 'react';

interface HydrasoloIframeProps {
  token: string;
  hstiId: string;
}

const HydrasoloIframe: FC<HydrasoloIframeProps> = ({ token, hstiId }) => {
  return (
    <iframe
      src={`https://hsti.tago.run/dashboards/info/${hstiId}?anonymousToken=${token}`}
      title="Hydrasolo Dashboard"
      style={{ width: '100%', height: '100%', border: 'none' }}
    />
  );
};

export default HydrasoloIframe;
