import React, { FC } from 'react';
import { defineRoute } from '@core/router';
import { defineLoader, httpTaskToResponseTask, useLoader } from '@core/router/loader';
import { pipe } from 'fp-ts/function';
import { z } from 'zod';
import { AlertReport } from '@modules/alert-reports/model';
import { AlertReportsService } from '@modules/alert-reports/service';
import { Button, Card, Group, Stack, Text } from '@mantine/core';
import ReportDateCard from '@modules/alert-reports/components/ReportDateCard';
import AlertReportGalleryCard from '@modules/alert-reports/components/AlertReportGalleryCard';
import CommentsCards from '@modules/alert-reports/components/CommentsCards';
import { IconChartBar } from '@tabler/icons-react';
import { Link } from 'react-router-dom';

const params = z.object({ id: AlertReport.Id });

const loader = defineLoader({
  params,
  handler: ({ params }) => pipe(httpTaskToResponseTask(AlertReportsService.getAlertReport(params.id))),
});

const AlertReportMapDetail: FC = () => {
  const alertReport = useLoader<typeof loader>();

  return (
    <Card radius={10} shadow="0 4px 7px rgba(0, 0, 0, 0.15)" bg="white" p={20} pb={25} withBorder={false}>
      <Stack>
        <Text color="primary.5" weight={700} size={22}>
          {alertReport.type.label}
        </Text>
        <ReportDateCard reportedAt={alertReport.reportedAt} />
        <AlertReportGalleryCard alertReport={alertReport} />

        <CommentsCards alertReport={alertReport} />

        <Group position="center">
          <Button
            component={Link}
            to={`/alert-reports/analysis/${alertReport.id}`}
            relative="path"
            leftIcon={<IconChartBar size={16} />}>
            Consulter l'analyse
          </Button>
        </Group>
      </Stack>
    </Card>
  );
};

const alertReportMapDetailRoute = defineRoute({
  component: AlertReportMapDetail,
  loader,
});

export default alertReportMapDetailRoute;
