import React, { FC } from 'react';
import { CustomPointFeature, MarkerWithLocation } from '@shared/hooks/supercluster';
import GoogleMapsOverlay from '@shared/modules/maps/components/GoogleMapsOverlay';
import SensorPin from '@shared/modules/maps/components/SensorPin';
import { SensorsMap } from '@modules/iot/sensors/map/model';
import Supercluster from 'supercluster';
import PreserveSearchLink from '@core/router/components/PreserveSearchLink';
import MapClustering from '@shared/modules/maps/components/MapClustering';

interface MarkersProps {
  map: google.maps.Map;
  markers: Array<MarkerWithLocation<SensorsMap.Marker>>;
  supercluster: Supercluster<CustomPointFeature<SensorsMap.Marker>>;
}

const IotMapClustering: FC<MarkersProps> = ({ map, markers, supercluster }) => {
  return (
    <MapClustering map={map} markers={markers} supercluster={supercluster}>
      {marker => (
        <GoogleMapsOverlay
          key={marker.id}
          position={{ lng: marker.location.longitude, lat: marker.location.latitude }}
          map={map}>
          <SensorPin
            component={PreserveSearchLink}
            to={`/iot/sensors/${marker.id}`}
            display="block"
            w="100%"
            h="100%"
            type={marker.type}
            tip={marker.name}
            level={marker.alertLevel ?? undefined}
          />
        </GoogleMapsOverlay>
      )}
    </MapClustering>
  );
};

export default IotMapClustering;
