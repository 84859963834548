import React, { createElement, FC } from 'react';
import { ActionIcon, Card, Group, Text, ThemeIcon, Title, Tooltip } from '@mantine/core';
import { ActiveSensor, Sensor } from '@modules/iot/sensors/model';
import { IconTrash } from '@tabler/icons-react';
import { renderNullable, renderOptional } from '@shared/utils/render';
import * as O from 'fp-ts/Option';
import { Measures } from '@shared/modules/measures/model';
import { MeasuresUtils } from '@shared/modules/measures/utils';
import batteryLevel = MeasuresUtils.batteryLevel;
import RealTimeMeasure = Measures.RealTimeMeasure;
import { useAction } from '@core/router/action';
import * as TE from 'fp-ts/TaskEither';
import { pipe } from 'fp-ts/function';
import { usePreserveNavigate } from '@core/router';
import { modals } from '@mantine/modals';
import * as NEA from 'fp-ts/NonEmptyArray';
import * as A from 'fp-ts/Array';
import { SensorAction } from '@modules/iot/sensors/action';

interface SensorHeaderProps {
  sensor: Omit<ActiveSensor, 'measures'>;
  battery: O.Option<RealTimeMeasure.Impl<Measures.Type.Battery>>;
  signal: O.Option<RealTimeMeasure.Impl<Measures.Type.Signal>>;
}

const ActiveSensorHeader: FC<SensorHeaderProps> = ({ sensor, battery, signal }) => {
  const [deleteLoading, deleteSensor] = useAction(SensorAction.deleteSensor);
  const navigate = usePreserveNavigate();

  const onConfirmDelete = () =>
    pipe(
      deleteSensor(sensor.id),
      TE.chainIOK(() => () => navigate('/iot/sensors/list', { replace: true })),
    )();

  const handleDelete = () =>
    modals.openConfirmModal({
      title: <Title size="h3">Êtes vous sûr ?</Title>,
      size: 400,
      centered: true,
      children: <>Supprimer {sensor.name}.</>,
      labels: { confirm: 'Supprimer la sonde', cancel: 'Annuler' },
      cancelProps: { variant: 'subtle', color: 'gray' },
      onConfirm: onConfirmDelete,
    });

  return (
    <Group py={15} position="apart">
      <Group>
        {createElement(Sensor.typeIcon[sensor.type], { width: 48, height: 48 })}
        <Text color="primary" weight={700} size={22}>
          Sonde {sensor.name}
          {renderNullable(sensor.zone, ({ name }) => (
            <Text component="span" color="gray.8" weight={600} size={12} ml={4} style={{ verticalAlign: 'middle' }}>
              - {name}
            </Text>
          ))}
        </Text>
      </Group>
      <Group spacing={20}>
        {renderOptional(NEA.fromArray(A.compact<RealTimeMeasure>([battery, signal])), () => (
          <Card px={10} py={4} radius={8} withBorder={false} bg="tertiary.2">
            <Group>
              {renderOptional(signal, ({ value }) => (
                <Tooltip label={Measures.signalStrengthLabel[value]} withinPortal>
                  <ThemeIcon size={31} variant="transparent" c="tertiary.8">
                    {createElement(Measures.signalStrengthIcon[value], { strokeWidth: 1, size: 31 })}
                  </ThemeIcon>
                </Tooltip>
              ))}

              {renderOptional(battery, ({ value }) => (
                <Tooltip label={Measures.batteryLabel[batteryLevel(value)]} withinPortal>
                  <ThemeIcon size={31} variant="transparent" c={value < 0.01 ? 'dark.1' : 'tertiary.8'}>
                    {createElement(Measures.batteryIcon[batteryLevel(value)], { strokeWidth: 1, size: 31 })}
                  </ThemeIcon>
                </Tooltip>
              ))}
            </Group>
          </Card>
        ))}

        <Tooltip label="Supprimer" position="bottom">
          <ActionIcon
            variant="light"
            bg="tertiary.2"
            size={39}
            radius={4}
            c="tertiary.4"
            loading={deleteLoading}
            onClick={handleDelete}>
            <IconTrash strokeWidth={1} />
          </ActionIcon>
        </Tooltip>
      </Group>
    </Group>
  );
};

export default ActiveSensorHeader;
