import React, { FC } from 'react';
import Page from '@layout/page/Page';
import { Badge, Box, Card, Divider, Group, SimpleGrid, Stack, Text } from '@mantine/core';
import { z } from 'zod';
import { defineLoader, httpTaskToResponseTask, useLoader } from '@core/router/loader';
import { defineRoute } from '@core/router';
import { Product } from '@modules/alert-reports/products/model';
import { ProductService } from '@modules/alert-reports/products/service';
import { renderNullable, renderOptional } from '@shared/utils/render';
import * as NEA from 'fp-ts/NonEmptyArray';
import { NumberUtils } from '@shared/utils/number';
import Editorial from '@shared/modules/editorial/Editorial';
import ImagesGallery from '@modules/alert-reports/components/ImagesGallery';
import PackagesSpoiler from '@modules/alert-reports/products/components/PackagesSpoiler';

const params = z.object({
  id: Product.Id,
});

const loader = defineLoader({
  params,
  handler: ({ params }) => httpTaskToResponseTask(ProductService.getProduct(params.id)),
});

const DetailPage: FC = () => {
  const product = useLoader<typeof loader>();
  const images = product.image ? [product.image, ...product.secondaryImages] : product.secondaryImages;

  return (
    <Page top="Détail produit" pl={47}>
      <SimpleGrid cols={2} style={{ gridTemplateColumns: '2fr 1fr' }}>
        <Stack spacing={30}>
          <Box fw={700}>
            <Text fz={22} fw={700} lh={1.4} c="primary">
              {product.label}
            </Text>
            <Text fz={16} lh={1.5} c="gray.8">
              {product.brand}
            </Text>
            <ImagesGallery images={images} mt={12} maw={355} />
          </Box>
          <Stack spacing={20}>
            <Text c="dark.5" fz={18} fw={700} lh={1.45}>
              Descriptif produit
            </Text>
            <Editorial editorial={product.content} />
          </Stack>
          <Stack spacing={10} c="dark.5" fz={18} fw={700} lh={1.45}>
            <Text>Revendeurs</Text>
            {renderOptional(
              NEA.fromArray(product.resellers),
              resellers => (
                <Box style={{ columnCount: 2, columnGap: 20 }}>
                  {resellers.map(({ id, label, catalog, packages }) => (
                    <Card key={id} w="100%" mb={20} radius={5} bg="#FCF8F5" px={14} py={15} withBorder={false}>
                      <Box pb={20}>
                        <Text c="primary" fz={16} fw={700} lh={1.5}>
                          Vendu par {label}
                        </Text>
                        <Text c="black" fz={14} fw={400} lh={1.55} opacity={0.5}>
                          {catalog}
                        </Text>
                      </Box>
                      {packages.map(({ id, label, price, oldPrice }, index) => (
                        <>
                          <Group key={id} position="apart" fz={16} fw={400} lh={1.55}>
                            <Text>{label}</Text>
                            {renderNullable(price, price => (
                              <Text>
                                {NumberUtils.formatPrice(price)}{' '}
                                {renderNullable(oldPrice, old => (
                                  <s>({NumberUtils.formatPrice(old)})</s>
                                ))}
                              </Text>
                            ))}
                          </Group>
                          {index < packages.length - 1 && <Divider pb={10} mt={10} />}
                        </>
                      ))}
                    </Card>
                  ))}
                </Box>
              ),
              () => (
                <Text c="gray.7" fz={14}>
                  Aucun revendeur
                </Text>
              ),
            )}
          </Stack>
        </Stack>

        <Stack>
          <Stack>
            <Text c="dark.5" fz={18} fw={700} lh={1.45} pl={20}>
              Où acheter ce produit ?
            </Text>
            {renderOptional(NEA.fromArray(product.retailPoints), points => (
              <Stack
                spacing={12}
                pl={30}
                sx={theme => ({ borderLeft: `1px solid ${theme.colors.gray[3]}`, borderRadius: 8 })}>
                {points.map(({ id, label, reseller, packages, address, distanceInKilometers }) => (
                  <Card key={id} shadow="2px 2px 10px 0px rgba(0, 0, 0, 0.05)" p={15} withBorder={false}>
                    <Group position="apart">
                      <Text c="black" fz={12} fw={400} lh={1.55} opacity={0.5}>
                        {reseller}
                      </Text>
                      {renderNullable(distanceInKilometers, distance => (
                        <Badge
                          h="auto"
                          px={5}
                          py={3}
                          bg="tertiary.3"
                          fz={10}
                          fw={600}
                          lh={1.55}
                          c="rgba(0, 0, 0, 0.75)"
                          radius={0}>
                          {NumberUtils.formatNumber(distance, { style: 'unit', unit: 'kilometer' })}
                        </Badge>
                      ))}
                    </Group>
                    <Text c="black" fz={16} fw={700} lh={1.5}>
                      {label}
                    </Text>
                    <Divider my={10} />
                    <Text c="black" fz={14} fw={400} lh={1.55}>
                      {address}
                    </Text>
                    {renderOptional(NEA.fromArray(packages), packages => (
                      <PackagesSpoiler index={id} packages={packages} />
                    ))}
                  </Card>
                ))}
              </Stack>
            ))}
          </Stack>
        </Stack>
      </SimpleGrid>
    </Page>
  );
};

const productDetailRoute = defineRoute({
  component: DetailPage,
  loader,
});

export default productDetailRoute;
