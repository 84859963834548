import { FC } from 'react';
import { defineRoute } from '@core/router';
import Page from '@layout/page/Page';
import HydrasoloIframe from '@modules/iot/hydrasolo/dashboard/components/HydrasoloIframe';
import { useProfile } from '@modules/profile/loader';
import config from '@config/index';
import { HydrasoloPresentation } from '@modules/iot/hydrasolo/dashboard/components/HydrasoloPresentation';

const DashboardPage: FC = () => {
  const profile = useProfile();
  const hasAccessToHydrasolo = !!profile.company.hstiId;
  return (
    <Page top="Tableau de bord HYDRASOLO">
      {hasAccessToHydrasolo && <HydrasoloIframe token={config.VITE_HSTI_TOKEN} hstiId={profile.company.hstiId!} />}
      {!hasAccessToHydrasolo && <HydrasoloPresentation />}
    </Page>
  );
};

const hydrasoloDashboardRoute = defineRoute({
  component: DashboardPage,
  loader: undefined,
});

export default hydrasoloDashboardRoute;
