import { Route } from 'react-router-dom';
import sensorsRoutes from '@modules/iot/sensors/routes';
import alertTriggersRoutes from '@modules/iot/alert-triggers/routes';
import dashBoardRoutes from '@modules/iot/dashboard/routes';
import hydrasoloRoutes from '@modules/iot/hydrasolo/routes';

const iotRoutes = (
  <Route path="iot">
    {dashBoardRoutes}
    {hydrasoloRoutes}
    {sensorsRoutes}
    {alertTriggersRoutes}
  </Route>
);

export default iotRoutes;
