import React, { FC } from 'react';
import { ActionIcon, Group, SimpleGrid, Text } from '@mantine/core';
import WeatherParamDescriptionDrawer from '@shared/modules/previsions/components/weather/WeatherParamDescriptionDrawer';
import { IconHelp } from '@tabler/icons-react';
import { useHashDisclosure } from '@core/router';

const ListPageHeader: FC = () => {
  const [helpWeatherOpen, { open: openHelpWeather, close: closeHelpWeather }] = useHashDisclosure('#help-weather');

  return (
    <>
      <SimpleGrid
        cols={7}
        c="dark.1"
        fz={10}
        fw={600}
        lh={1.55}
        px={20}
        py={20}
        style={{ alignItems: 'center', gridTemplateColumns: '1fr repeat(4, 2fr) 1fr 50px' }}>
        <Text>Date d’émission</Text>
        <Text>Signalement</Text>
        <Text>Catégorie</Text>
        <Group spacing={4}>
          <Text>Conditions météo</Text>
          <ActionIcon onClick={openHelpWeather} size={20} c="#2C3E50">
            <IconHelp strokeWidth={1.5} />
          </ActionIcon>
        </Group>
        <Text>Scoring</Text>
        <Text>Nb sondes (à 5km ou moins du signalement)</Text>
      </SimpleGrid>
      <WeatherParamDescriptionDrawer opened={helpWeatherOpen} onClose={closeHelpWeather} />
    </>
  );
};

export default ListPageHeader;
