import React, { createElement, FC } from 'react';
import { Box, Card, Group, Stack, Text, ThemeIcon } from '@mantine/core';
import { flow, pipe } from 'fp-ts/function';
import * as NEA from 'fp-ts/NonEmptyArray';
import * as O from 'fp-ts/Option';
import { isAfter, parseISO } from 'date-fns';
import { renderOptional } from '@shared/utils/render';
import { DateFormat, formatDate } from '@shared/modules/dates';
import { Measures } from '@shared/modules/measures/model';
import { LastMeasuresUtils } from '@shared/modules/measures/last/utils';
import MeasureValue = LastMeasuresUtils.MeasureValue;
import levelColor = LastMeasuresUtils.levelColor;
import measureIcon = LastMeasuresUtils.measureIcon;
import NumericMeasure = LastMeasuresUtils.NumericMeasure;
import MeasureProgress from '@shared/modules/measures/last/MeasureProgress';

interface MeasureCardProps<Type extends NumericMeasure> {
  title: { label: string; icon: FC<React.ComponentProps<'svg'>> };
  measures: Array<MeasureValue<Type>>;
  distance: { label: string; value: string } | null;
  withoutDistance?: true;
  withoutDate?: true;
}

function MeasureCard<Type extends NumericMeasure>({
  distance,
  title,
  measures,
  withoutDistance,
  withoutDate,
}: MeasureCardProps<Type>) {
  const lastDate = pipe(
    NEA.fromArray(measures),
    O.map(
      flow(NEA.unprepend, ([first, tail]) =>
        tail.reduce((old, { measure: { at } }) => {
          const next = parseISO(at);
          return isAfter(next, old) ? next : old;
        }, parseISO(first.measure.at)),
      ),
    ),
    O.filter(() => !withoutDate),
  );

  const optDistance = pipe(
    O.fromNullable(distance),
    O.filter(() => !withoutDistance),
  );

  return (
    <Stack>
      <Card
        px={14}
        py={0}
        sx={theme => ({ '&[data-with-border]': { border: `1px solid ${theme.colors.tertiary[2]}` } })}>
        <Group spacing={4} py={10} mb={10} sx={theme => ({ borderBottom: `2px solid ${theme.colors.tertiary[2]}` })}>
          <ThemeIcon c="dark.1" bg="transparent">
            {createElement(title.icon)}
          </ThemeIcon>
          <Text color="dark.1" size={12} weight={600}>
            {title.label}
          </Text>
        </Group>

        {measures.map(({ measure: { value, type }, scale }) => (
          <Box key={type}>
            <Group spacing={8}>
              <ThemeIcon
                size={22}
                variant="light"
                color={levelColor[LastMeasuresUtils.getCurrentScale(scale, value, type).level]}>
                {createElement(measureIcon[type], { size: 12 })}
              </ThemeIcon>
              <Text size={14} color="dark.5" weight={600}>
                {Measures.typeTitle[type]}
              </Text>
            </Group>
            <MeasureProgress value={value} type={type} scale={scale} />
          </Box>
        ))}
        {renderOptional(optDistance, ({ label, value }) => (
          <Card bg="tertiary.2" withBorder={false} p={8} radius={8} w="fit-content" mb={14}>
            <Text size={12} weight={600}>
              <Text component="span" color="dark.2">
                {label} :{' '}
              </Text>
              {value}
            </Text>
          </Card>
        ))}
      </Card>
      {renderOptional(lastDate, date => (
        <Text color="dark.5" weight={700} size={10}>
          Dernier relevé{' '}
          <Text component="span" color="dark.1" weight={600}>
            {formatDate(date, DateFormat.LocalDateTime)}
          </Text>
        </Text>
      ))}
    </Stack>
  );
}

export default MeasureCard;
