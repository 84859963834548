import { ModalContent, SharedButton } from '@styles/shared';
import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import React, { FC, MouseEvent, PropsWithChildren, ReactNode } from 'react';
import { Group, Modal, Text } from '@mantine/core';
import { IconTrash } from '@tabler/icons-react';

export interface DeleteModalButton {
  text?: string;
  icon?: ReactNode;
}

interface DeleteModalProps {
  title?: string;
  loading?: boolean;
  button?: DeleteModalButton;
  onDelete: () => void;
  handleClose: () => void;
  opened: boolean;
}

const DeleteModal: FC<PropsWithChildren<DeleteModalProps>> = ({
  title = 'Êtes-vous sûr(e) de vouloir supprimer cet élément ?',
  loading,
  button,
  onDelete,
  children = 'La suppression est définitive.',
  opened,
  handleClose,
}) => {
  const stopPropagation = (e: MouseEvent) => e.stopPropagation();

  const handleSubmit = (e: MouseEvent) => {
    stopPropagation(e);
    handleClose();
    onDelete();
  };

  const optButton = O.fromNullable(button);

  const buttonText = pipe(
    optButton,
    O.chainNullableK(b => b.text),
    O.getOrElse(() => 'Supprimer'),
  );

  const buttonIcon = pipe(
    optButton,
    O.chainNullableK(b => b.icon),
    O.getOrElse<ReactNode>(() => <IconTrash />),
  );

  return (
    <Modal
      opened={opened}
      centered
      onClose={handleClose}
      onClick={stopPropagation}
      title={
        <Text size="lg" weight="600">
          {title}
        </Text>
      }
      withCloseButton={false}
      size={500}>
      <ModalContent>
        <Text size="sm">{children}</Text>
      </ModalContent>

      <Group position="right">
        <SharedButton btnType="cancel" onClick={handleClose} disabled={loading} />
        <SharedButton
          btnType="delete"
          onClick={handleSubmit}
          disabled={loading}
          loading={loading}
          children={buttonText}
          leftIcon={buttonIcon}
        />
      </Group>
    </Modal>
  );
};

export default DeleteModal;
