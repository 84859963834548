import React, { FC } from 'react';
import { Link, To } from 'react-router-dom';
import { Card, CardProps, Group, MantineColor, Text } from '@mantine/core';
import { Threshold } from '@modules/iot/model';
import Level = Threshold.Level;
import { stringifyQueries } from '@shared/utils/queries';
import { ActiveSensor } from '@modules/iot/sensors/model';

const dashboardStatsLevelBg: Record<Level, MantineColor> = {
  [Level.None]: 'green',
  [Level.Alert]: 'complementary.1',
  [Level.Critical]: 'primary.1',
};

const dashboardStatsLevelColor: Record<Level, MantineColor> = {
  [Level.None]: 'green',
  [Level.Alert]: 'primary.4',
  [Level.Critical]: 'primary',
};

const dashboardStatsLevelText: Record<Level, MantineColor> = {
  [Level.None]: 'Sonde(s) None',
  [Level.Alert]: 'Sonde(s) en alerte',
  [Level.Critical]: 'Sonde(s) critique(s)',
};

interface DashboardStatsCardProps {
  level: Level;
  count: number;
}

const DashboardStatsCard: FC<DashboardStatsCardProps & Omit<CardProps, 'children'>> = ({ level, count, ...rest }) => {
  const filterTo = (alertLevel: Level): To => ({
    pathname: '/iot/sensors/list',
    search: stringifyQueries<Partial<ActiveSensor.Filter>>({ alertLevel }),
  });

  return (
    <Card
      component={Link}
      to={filterTo(level)}
      bg={dashboardStatsLevelBg[level]}
      c={dashboardStatsLevelColor[level]}
      p={17}
      radius={8}
      withBorder={false}
      {...rest}>
      <Group spacing={20} noWrap>
        <Text size={34} weight={700}>
          {count}
        </Text>
        <Text size={16}>{dashboardStatsLevelText[level]}</Text>
      </Group>
    </Card>
  );
};

export default DashboardStatsCard;
