import React, { FC, useCallback, useMemo, useState } from 'react';
import { ActionIcon, Card, Group, Stack, Text } from '@mantine/core';
import { IconDownload } from '@tabler/icons-react';
import RangeFilter from '@modules/iot/sensors/sensoterra/components/RangeFilter';
import { differenceInDays, differenceInWeeks, endOfToday, parseISO, startOfToday } from 'date-fns';
import { DatesRangeValue } from '@mantine/dates/lib/types/DatePickerValue';
import { DateFormat, formatDate } from '@shared/modules/dates';
import { Utils } from '@shared/utils/model';
import { useFetchTaskOption } from '@core/http/hooks';
import { ActiveSensor, Sensor } from '@modules/iot/sensors/model';
import { pipe } from 'fp-ts/function';
import SensorLineChart from '@shared/modules/charts/line/SensorLineChart';
import { SensorsService } from '@modules/iot/sensors/service';
import * as R from 'fp-ts/Record';
import * as S from 'fp-ts/string';
import * as M from 'fp-ts/Monoid';
import * as A from 'fp-ts/Array';
import * as O from 'fp-ts/Option';
import { renderOptional } from '@shared/utils/render';
import { Measures } from '@shared/modules/measures/model';
import Identifier = Sensor.Probe.Identifier;
import Type = Measures.Type;
import { MeasuresUtils } from '@shared/modules/measures/utils';
import mergeDateMonoid = MeasuresUtils.mergeDateMonoid;
import { useAction } from '@core/router/action';
import { SensorAction } from '@modules/iot/sensors/action';
import SensorMeasureSelection from '@shared/modules/measures/SensorMeasureSelection';

interface SensorMeasureProps {
  sensor: ActiveSensor;
}

const defaultSelection = Object.values(Identifier).flatMap(identifier =>
  Object.values(Type).map(type => `${identifier}.${type}` as const),
);

const SensorMeasure: FC<SensorMeasureProps> = ({ sensor }) => {
  const [exportLoading, exportTask] = useAction(SensorAction.exportMeasures);
  const [selection, setSelection] = useState(defaultSelection);
  const [[startDate, endDate], setDateRange] = useState([startOfToday(), endOfToday()]);

  const onDateRangeChange = ([startDate, endDate]: DatesRangeValue) => {
    if (startDate && endDate) setDateRange([startDate, endDate]);
  };

  const filter: Measures.History.Filter = useMemo(
    () => ({
      startDate: formatDate(startDate, DateFormat.LocalDateTime),
      endDate: formatDate(endDate, DateFormat.LocalDateTime),
      unit: differenceInWeeks(endDate, startDate) < 1 ? Utils.ChronoUnit.Hours : Utils.ChronoUnit.Days,
    }),
    [startDate, endDate],
  );

  const measuresTask = useCallback(
    () => SensorsService.getMeasures(sensor.id, sensor.type, filter),
    [sensor.id, sensor.type, filter],
  );

  const [measures] = useFetchTaskOption(measuresTask);

  const data = pipe(
    measures,
    O.fold(
      () => [],
      sensors =>
        pipe(
          R.toEntries(sensors),
          A.chain(([identifier, values]) =>
            pipe(
              values,
              A.filter(({ type }) => selection.some(key => key === `${identifier}.${type}`)),
              A.map(({ values, type }) =>
                values.reduce((acc, curr) => ({ ...acc, [curr.at]: { [`${identifier}.${type}`]: curr.value } }), {}),
              ),
            ),
          ),
          M.concatAll(mergeDateMonoid),
          R.collect(S.Ord)((at, values) => ({ date: parseISO(at), ...values })),
        ),
    ),
  );

  const dateFormatter = (date: Date) =>
    formatDate(date, differenceInDays(endDate, startDate) < 1 ? 'HH:mm' : 'dd/MM/yyyy');

  const handleExport = () => exportTask({ type: sensor.type, ...filter });

  return (
    <Card
      p={22}
      radius={10}
      shadow="0px 4px 10px rgba(0, 0, 0, 0.05)"
      sx={theme => ({
        '&[data-with-border]': { border: `1px solid ${theme.colors.tertiary[2]}` },
      })}>
      <Stack px={22}>
        <Group position="apart">
          <Text size={18} weight={700} color="dark.5">
            Données de capteurs
          </Text>
          <ActionIcon
            variant="light"
            size={36}
            color="tertiary.2"
            c="tertiary.8"
            loading={exportLoading}
            onClick={handleExport}>
            <IconDownload strokeWidth={1} />
          </ActionIcon>
        </Group>
        <Group position="center">
          <RangeFilter dateRange={[startDate, endDate]} onDateRangeChange={onDateRangeChange} />
        </Group>
        <SensorLineChart data={data} dateFormatter={dateFormatter} />
        {renderOptional(measures, measures => (
          <SensorMeasureSelection measures={measures} selection={selection} setSelection={setSelection} />
        ))}
      </Stack>
    </Card>
  );
};

export default SensorMeasure;
