import React from 'react';
import { Box, Progress, Text } from '@mantine/core';
import { LastMeasuresUtils } from '@shared/modules/measures/last/utils';
import { IconGenderNeutrois } from '@tabler/icons-react';
import { Threshold } from '@modules/iot/model';
import { Measures } from '@shared/modules/measures/model';
import NumericMeasure = LastMeasuresUtils.NumericMeasure;
import levelColor = LastMeasuresUtils.levelColor;
import { pipe } from 'fp-ts/function';
import * as A from 'fp-ts/Array';
import * as NEA from 'fp-ts/NonEmptyArray';
import { ProgressProps } from '@mantine/core/lib/Progress/Progress';

interface MeasurePointerProps<Type extends NumericMeasure> {
  scale: Threshold.Scale<Measures.Value<Type>, Threshold.Level>;
  type: Type;
  value: Measures.Value<Type>;
}

function MeasureProgress<Type extends NumericMeasure>({ type, scale, value }: MeasurePointerProps<Type>) {
  const max = Math.max(LastMeasuresUtils.MaxMeasure[type], value);
  const min = Math.min(LastMeasuresUtils.MinMeasure[type], value);

  const levels = pipe(scale.levels, A.append({ until: max, level: scale.last }));

  const sections = pipe(
    levels,
    NEA.reduce<(typeof levels)[number], NonNullable<ProgressProps['sections']>>([], (acc, val) => [
      ...acc,
      {
        value: val.until - acc.reduce((sum, { value }) => sum + value, min.valueOf()),
        color: levelColor[val.level],
      },
    ]),
  );

  return (
    <Box pos="relative" my={40}>
      <Progress
        h={4}
        sections={sections.map(({ value, color }) => ({
          value: (value * 100) / (max - min),
          color,
        }))}
      />
      <IconGenderNeutrois
        strokeWidth={1.4}
        size={20}
        style={{
          position: 'absolute',
          top: 2,
          left: `${((value - min) / (max - min)) * 100}%`,
          transform: 'rotate(0.5turn) translateX(50%) translateY(16px)',
        }}
      />
      <Text
        pos="absolute"
        top={0}
        color="dark.5"
        left={`${((value - min) / (max - min)) * 100}%`}
        weight={700}
        size={14}
        style={{ transform: 'translateX(-50%) translateY(-150%)' }}>
        {LastMeasuresUtils.formatter(value, type)}
      </Text>
    </Box>
  );
}

export default MeasureProgress;
