import { Image, Title, Text, List, Grid, Space, Box, Anchor } from '@mantine/core';
import logo from '@assets/images/hydrasolo/logo.png';
import sensor from '@assets/images/hydrasolo/sensor.png';

export const HydrasoloPresentation = () => {
  return (
    <Grid gutter="lg" align="center">
      <Grid.Col xs={12} sm={5} md={5} lg={4}>
        <Image src={sensor} alt="HydraSolo Sensor" />
      </Grid.Col>

      <Grid.Col xs={12} sm={12} md={12} lg={8}>
        <Image src={logo} alt="HydraSolo Logo" width="92%" style={{ maxWidth: 300 }} />
        <Space h="md" />
        <Title order={3}>Supervisez l’état hydrique de vos sols grâce à HydraSolo</Title>

        <Space h="sm" />
        <Text>Mesurez l’humidité et la température dans un large volume de terre.</Text>
        <Text>Bénéficiez d’un accompagnement personnalisé avec Odusol, le jumeau numérique de vos sols.</Text>
        <Text>Visualisez facilement et en direct les dynamiques de l’eau dans vos sols.</Text>
        <Space h="md" />
        <List spacing="xs">
          <List.Item>Une sonde robuste, garantie 5 ans</List.Item>
          <List.Item>Compatible avec tous types de sols</List.Item>
          <List.Item>Capteur positionné à l’horizon de votre choix</List.Item>
          <List.Item>Installation simple et « plug & play » (kit fourni)</List.Item>
          <List.Item>Mesure précise et fiable, validée en laboratoire</List.Item>
          <List.Item>Volume de lecture supérieur (1310 cm³ de sol)</List.Item>
          <List.Item>Connectivité cellulaire étendue avec position GPS</List.Item>
          <List.Item>Modularité jusqu’à 4 HydraSolo par boîtier de communication*</List.Item>
          <List.Item>
            <Text weight={800}>Visualisation en temps réel de votre Réserve Utile</Text>
          </List.Item>
        </List>

        <Space h="sm" />
        <Text color="dimmed" fs="italic">
          *Distance maximale de 20m entre la sonde et son datalogger
        </Text>
      </Grid.Col>
      <Box>
        <Text>Pour plus d’informations ou pour commander votre sonde Hydrasolo, contactez-nous :</Text>

        <List spacing="xs">
          <List.Item>
            <Text>
              <span style={{ fontWeight: 800 }}>Email : </span>
              <Anchor href="mailto:contact@platform.garden">contact@platform.garden</Anchor>
            </Text>
          </List.Item>
          <List.Item>
            <Text>
              <span style={{ fontWeight: 800 }}>Tél : </span>
              <Anchor href="tel:0547500299">0547500299</Anchor>
            </Text>
          </List.Item>
        </List>
      </Box>
    </Grid>
  );
};
