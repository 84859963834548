import { CSSProperties, ReactNode } from 'react';

import isEqual from 'lodash.isequal';

import * as O from 'fp-ts/Option';

import { Filter } from '@shared/modules/filter';

export class RangeCursor {
  private static readonly DEFAULT_SIZE = 50;

  constructor(
    public startIndex: number,
    public endIndex: number,
    public size: number = RangeCursor.DEFAULT_SIZE,
  ) {}

  static fromPage(page: number, size: number = RangeCursor.DEFAULT_SIZE) {
    return new RangeCursor((page - 1) * size, page * size - 1);
  }

  static initial(size: number = RangeCursor.DEFAULT_SIZE) {
    return RangeCursor.fromPage(0, size);
  }

  static fromIndex(index: number, size: number = RangeCursor.DEFAULT_SIZE) {
    const startIndex = Math.max(0, index - size / 2);

    return new RangeCursor(startIndex, startIndex + size - 1, size);
  }

  toPage() {
    return Math.floor(this.startIndex / this.size) + 1;
  }

  get queries() {
    return {
      startIndex: this.startIndex,
      endIndex: this.endIndex,
    };
  }
}

export interface RangeResult<T, F extends Filter = {}> extends Required<Omit<RangeCursor, 'size'>> {
  total: number;
  items: Array<T>;
  filter: F;
  sort: string | null;
  cursorSize: number;
}

export class Range<T, F extends Filter = {}> {
  constructor(
    readonly items: ReadonlyMap<number, T>,
    readonly total: number,
    readonly filter: F,
    readonly cursorSize: number,
  ) {}

  merge(newRange: Range<T, F>): Range<T, F> {
    if (isEqual(this.filter, newRange.filter)) {
      return new Range<T, F>(
        new Map<number, T>([...Array.from(this.items.entries()), ...Array.from(newRange.items.entries())]),
        newRange.total,
        newRange.filter,
        newRange.cursorSize,
      );
    }

    return newRange;
  }

  has(index: number): boolean {
    return this.items.has(index);
  }

  get(index: number): O.Option<T> {
    return O.fromNullable(this.items.get(index));
  }

  toList(): Array<T> {
    return Array.from(this.items.values());
  }

  map<B>(fa: (a: T) => B): Range<B, F> {
    return new Range(
      new Map<number, B>(Array.from(this.items, ([key, value]) => [key, fa(value)])),
      this.total,
      this.filter,
      this.cursorSize,
    );
  }

  static fromRangeResult<T, F extends Filter = {}>(result: RangeResult<T, F>) {
    return new Range(
      new Map<number, T>(result.items.map((item, i) => [i + result.startIndex, item])),
      result.total,
      result.filter,
      result.cursorSize,
    );
  }

  static fromArray<T, F extends Filter = {}>(list: Array<T>, filter: F): Range<T, F> {
    return new Range<T, F>(new Map<number, T>(list.map((item, i) => [i, item])), list.length, filter, list.length);
  }
}

export interface VirtualizedListChildrenProps<T> {
  item: T;
  index: number;
  style: CSSProperties;

  ref: (element: Element | null) => void;
}

export interface VirtualizedListProps<T> {
  header?: ReactNode;
  range: Range<T>;
  rowHeight?: number;
  loadPage: (page: number) => void;
  emptyMessage?: string;
  children: (props: VirtualizedListChildrenProps<T>) => ReactNode;
}
