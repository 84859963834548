import React, { FC } from 'react';
import { Box, Card, Group, Image, Stack, Text } from '@mantine/core';
import { ActiveSensor, Sensor } from '@modules/iot/sensors/model';
import GoogleMaps from '@shared/modules/maps/components/GoogleMaps';
import { Maps } from '@shared/modules/maps/model';
import SensorPin from '@shared/modules/maps/components/SensorPin';
import GoogleMapsOverlay from '@shared/modules/maps/components/GoogleMapsOverlay';
import { identity } from 'fp-ts/function';
import MeasureCards from '@shared/modules/measures/last/MeasureCards';
import { Threshold } from '@modules/iot/model';

interface DetailContentProps {
  sensor: ActiveSensor;
  formatDistance?(distance: string): string | null;
  level: Threshold.Level;
}

const DetailContent: FC<DetailContentProps> = ({ sensor, formatDistance = identity, level }) => {
  const position: google.maps.LatLngLiteral = { lat: sensor.location.latitude, lng: sensor.location.longitude };

  return (
    <Card
      p={22}
      withBorder={false}
      shadow="0px 4px 10px rgba(0, 0, 0, 0.05)"
      sx={theme => ({ border: `1px solid ${theme.colors.tertiary[2]}` })}>
      <Stack>
        <Group position="apart">
          <Text color="dark.5" size={18} weight={700} lh={1.45}>
            Détail sonde
          </Text>
          <Image height={60} width="fit-content" fit="contain" src={Sensor.typeLogo[sensor.type]} />
        </Group>
        <Box h={175} pos="relative">
          <GoogleMaps
            options={{
              ...Maps.defaultOptions,
              center: position,
              gestureHandling: 'none',
            }}>
            {map => (
              <GoogleMapsOverlay position={position} map={map}>
                <SensorPin type={sensor.type} tip={sensor.name} level={level} />
              </GoogleMapsOverlay>
            )}
          </GoogleMaps>
        </Box>
        <Text color="dark.3" weight={600} size={10}>
          {sensor.location.latitude}, {sensor.location.longitude}
        </Text>
        <MeasureCards sensor={sensor} formatDistance={formatDistance} />
      </Stack>
    </Card>
  );
};

export default DetailContent;
