import React, { FC, PropsWithChildren } from 'react';
import { Drawer, ScrollArea, DrawerProps } from '@mantine/core';

interface FullDrawerProps extends DrawerProps {
  opened: boolean;
  width: number;
  onClose: () => void;
}

const DrawerScrollArea: DrawerProps['scrollAreaComponent'] = props => (
  <ScrollArea
    key="test"
    h="calc(100% - 65px)"
    styles={{ viewport: { '> div': { height: 'calc(100% - 65px)' } } }}
    {...props}
  />
);

const FullDrawer: FC<PropsWithChildren<FullDrawerProps>> = ({ opened, width, onClose, children, ...rest }) => {
  return (
    <Drawer
      opened={opened}
      onClose={onClose}
      position="right"
      size={width}
      transitionProps={{ transition: 'slide-left', timingFunction: 'ease' }}
      overlayProps={{ opacity: 0 }}
      scrollAreaComponent={DrawerScrollArea}
      styles={theme => ({
        body: { height: '100%', padding: 0 },
        inner: { top: 65, bottom: 0 },
        content: {
          boxShadow: '0 4px 15px rgba(0, 0, 0, 0.1)',
          borderTop: 'none !important',
          borderRight: 'none !important',
          borderBottom: 'none !important',
          borderLeft: `1.5px solid ${theme.colors.gray[1]} !important`,
        },
      })}
      withCloseButton={false}
      lockScroll={false}
      closeOnClickOutside
      {...rest}>
      {children}
    </Drawer>
  );
};

export default FullDrawer;
