import { AbsoluteUrl } from '@shared/schemas';
import z from 'zod';

export const PGImageId = z.string().uuid().brand('PGImageId');
export type PGImageId = z.infer<typeof PGImageId>;

export enum PGImageVariant {
  Original = 'original',
  Bound_256x256 = 'bound_256x256',
  Bound_512x512 = 'bound_512x512',
  Fit_300x300 = 'fit_300x300',
  Cover_150x150 = 'cover_150x150',
}

export type ImageMap = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  [P in PGImageVariant]?: AbsoluteUrl;
};

export interface PGImageDetail {
  id: PGImageId;
  variants: ImageMap;
}
