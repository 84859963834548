import React, { createElement, FC } from 'react';
import { defineRoute } from '@core/router';
import { defineLoader, httpTaskToResponseTask, useLoader } from '@core/router/loader';
import { z } from 'zod';
import { AspectRatio, Box, Card, Group, Image, SimpleGrid, Stack, Tabs, Text, Tooltip } from '@mantine/core';
import Page from '@layout/page/Page';
import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import * as NEA from 'fp-ts/NonEmptyArray';
import { renderNullable, renderOptional } from '@shared/utils/render';
import { IconPhoto } from '@tabler/icons-react';
import { Pest } from '@modules/alert-reports/pests/model';
import Editorial from '@shared/modules/editorial/Editorial';
import PestDateRange from '@modules/alert-reports/pests/components/PestDateRange';
import PestStage from '@modules/alert-reports/pests/components/PestStage';
import { PestService } from '@modules/alert-reports/pests/service';
import { sortListByOrder } from '@shared/utils/order';
import ImagesGallery from '@modules/alert-reports/components/ImagesGallery';
import AdviceCard from '@modules/alert-reports/pests/components/PestAdvices';
import { Link } from 'react-router-dom';
import * as TE from 'fp-ts/TaskEither';
import { DashboardService } from '@modules/dashboard/service';

const params = z.object({
  name: z.nativeEnum(Pest.Name),
});

const loader = defineLoader({
  params,
  handler: ({ params }) =>
    pipe(
      DashboardService.getContext(null),
      TE.chain(context => PestService.getPest(params.name, context)),
      httpTaskToResponseTask,
    ),
});

const DetailPage: FC = () => {
  const pest = useLoader<typeof loader>();

  return (
    <Page top="Détail vigilance bioagresseur" seoTitle={pest.name} pl={47}>
      <Box maw="100%">
        <Text fz={22} fw={700} c="primary" lh={1.4}>
          {pest.name}
        </Text>
        <SimpleGrid pt={35} spacing={30} style={{ gridTemplateColumns: 'minmax(0, 3fr) 1fr' }}>
          <Stack>
            <Group spacing={30} noWrap>
              <ImagesGallery images={pest.images ?? []} w={376} />
              <Stack spacing={16} mb="auto" style={{ flexGrow: 1, flexBasis: 0 }}>
                <Stack spacing={10}>
                  <Text fz={18} fw={700} lh={1.45} c="dark.5">
                    Stade
                  </Text>
                  <Card bg="#FCF8F5" withBorder={false} p={20} radius={10}>
                    {pipe(
                      NEA.fromArray(pest.stages),
                      O.matchW(
                        () => (
                          <Text c="gray.7" fz={14}>
                            Aucun risque de prévu pour cette semaine
                          </Text>
                        ),
                        NEA.mapWithIndex((index, { name, level, trend }) => (
                          <Group key={index} spacing={18}>
                            <Tooltip label={Pest.Detail.trendLabel[trend]} withinPortal>
                              <Box h={20} w={20}>
                                {createElement(Pest.Detail.trendIcon[trend], {
                                  width: 20,
                                  height: 20,
                                })}
                              </Box>
                            </Tooltip>
                            <PestStage key={index} name={name} level={level} />
                          </Group>
                        )),
                      ),
                    )}
                  </Card>
                </Stack>
                <PestDateRange dateRange={pest.dateRange} />
              </Stack>
            </Group>

            {renderNullable(pest.advice, advice => (
              <AdviceCard advice={advice} />
            ))}

            {renderOptional(NEA.fromArray(sortListByOrder(pest.coachs)), coachs => (
              <Tabs defaultValue={`${NEA.head(coachs).id}`} color="primary">
                <Tabs.List>
                  <Group spacing={0} noWrap style={{ overflowX: 'auto' }} mb={-2}>
                    {coachs.map(({ id, title }) => (
                      <Tabs.Tab
                        key={id}
                        value={`${id}`}
                        fw={700}
                        fz={16}
                        mb={0}
                        sx={theme => ({
                          color: theme.colors.dark[5],
                          '&[data-active]': { color: `${theme.colors.primary[5]}` },
                        })}>
                        {title}
                      </Tabs.Tab>
                    ))}
                  </Group>
                </Tabs.List>
                {coachs.map(({ id, content }) => (
                  <Tabs.Panel key={id} value={`${id}`} pt={30}>
                    <Editorial editorial={content} />
                  </Tabs.Panel>
                ))}
              </Tabs>
            ))}
          </Stack>

          {renderOptional(NEA.fromArray(pest.products), products => (
            <Stack
              spacing={12}
              pl={30}
              sx={theme => ({ borderLeft: `1px solid ${theme.colors.gray[3]}`, borderRadius: 8 })}>
              <Text c="dark.5" fz={18} fw={700} lh={1.45}>
                Produit associé aux conseils
              </Text>
              {products.map(({ id, image, brand, label }) => (
                <Card
                  component={Link}
                  to={`/alert-reports/products/${id}`}
                  key={id}
                  radius={5}
                  p={0}
                  shadow="1px 2px 10px 4px rgba(0, 0, 0, 0.06)"
                  withBorder={false}>
                  <Group spacing={0} noWrap>
                    <AspectRatio
                      ratio={1}
                      w={80}
                      sx={theme => ({
                        borderRadius: '5px 0px 0px 5px',
                        border: `1px solid ${theme.colors.gray[1]}`,
                      })}>
                      {renderNullable(
                        image?.bound_512x512,
                        src => (
                          <Image
                            src={src}
                            fit="contain"
                            styles={{
                              figure: { height: '100%' },
                              image: { maxHeight: '100%' },
                              imageWrapper: { height: '100%' },
                            }}
                          />
                        ),
                        () => (
                          <IconPhoto style={{ maxHeight: '60%', margin: 'auto' }} />
                        ),
                      )}
                    </AspectRatio>
                    <Stack spacing={0} p={14}>
                      <Text fw={700} fz={16} lh={1.5} c="black">
                        {label}
                      </Text>
                      <Text fw={400} fz={14} lh={1.55} c="gray.8">
                        {brand}
                      </Text>
                    </Stack>
                  </Group>
                </Card>
              ))}
            </Stack>
          ))}
        </SimpleGrid>
      </Box>
    </Page>
  );
};

const pestsDetailRoute = defineRoute({
  component: DetailPage,
  loader,
});

export default pestsDetailRoute;
