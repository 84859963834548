import React, { createElement, FC } from 'react';
import { Dashboard } from '@modules/iot/dashboard/model';
import { ActionIcon, Badge, Box, Group, Table, Text, ThemeIcon } from '@mantine/core';
import { pipe } from 'fp-ts/function';
import * as NEA from 'fp-ts/NonEmptyArray';
import { Sensor } from '@modules/iot/sensors/model';
import * as R from 'fp-ts/Record';
import * as A from 'fp-ts/Array';
import * as O from 'fp-ts/Option';
import { Measures } from '@shared/modules/measures/model';
import { renderOptional } from '@shared/utils/render';
import { Threshold } from '@modules/iot/model';
import { MeasuresUtils } from '@shared/modules/measures/utils';
import { IconEye } from '@tabler/icons-react';
import { Link } from 'react-router-dom';
import DashboardFilter from '@modules/iot/dashboard/components/DashboardFilter';
import * as M from 'fp-ts/Monoid';
import * as Ord from 'fp-ts/Ord';
import Identifier = Sensor.Probe.Identifier;
import identifierOrd = Dashboard.AlertSensorsZone.identifierOrd;

interface DashboardZoneTableProps {
  alerts: Dashboard.AlertSensorsZone;
  showFilter: boolean;
}

const mergeIdentifierMonoid: M.Monoid<Record<Identifier, Array<Measures.Type>>> = R.getUnionMonoid({
  concat: (x, y) => [...x, ...y],
});

const DashboardAlertsTable: FC<DashboardZoneTableProps> = ({ alerts, showFilter }) => {
  const probes = pipe(
    alerts.sensors,
    A.map(({ measures }) => pipe(measures, R.map(A.map(({ measure }) => measure.type)))),
    M.concatAll(mergeIdentifierMonoid),
    R.collect(identifierOrd as Ord.Ord<string>)(
      (identifier, values) => [identifier, A.uniq(Measures.typeEq)(values)] as const,
    ),
  );

  return (
    <Box
      sx={theme => ({
        ':not(:last-child)': { borderBottom: `1px solid ${theme.colors.gray[3]}`, paddingBottom: 19, marginBottom: 20 },
      })}>
      <Group position="apart">
        <Box>
          <Text color="primary" size={14} weight={700}>
            {alerts.name}
          </Text>
          <Text color="dark.4" size={10} weight={400}>
            {alerts.sensors.length} sonde(s)
          </Text>
        </Box>
        {showFilter && <DashboardFilter />}
      </Group>
      <Table
        captionSide="bottom"
        sx={theme => ({
          borderCollapse: 'separate',
          borderSpacing: '0 5px',
          'tbody > tr > td': {
            background: theme.colors.gray[0],
            whiteSpace: 'nowrap',
            padding: '6px 10px',
            borderTop: 'none',
            ':first-child': { borderRadius: '4px 0 0 4px' },
            ':nth-last-child(2)': {
              width: '100%',
              borderRadius: '0 4px 4px 0',
            },
          },
          'thead > tr > th': {
            fontWeight: 600,
            fontSize: 10,
            color: theme.colors.gray[6],
            border: 'none',
            paddingBottom: '0 10px',
          },
        })}>
        <thead>
          <tr>
            <th />
            {probes.map(([identifier, types], index) =>
              types.map(type => (
                <th key={`${identifier}.${type}`} data-index={`${identifier}.${type}`}>
                  {Measures.typeTitle[type]}
                  {A.replicate(index + 1, '*')}
                </th>
              )),
            )}
            <th />
          </tr>
        </thead>
        <tbody>
          {pipe(
            alerts.sensors,
            NEA.map(({ id, type, name, measures }) => (
              <tr key={id}>
                <td>
                  <Group spacing={8} noWrap>
                    <ThemeIcon c="tertiary.5" bg="transparent" size={24} style={{ border: 'none' }}>
                      {createElement(Sensor.typeIcon[type], { width: 14, height: 14 })}
                    </ThemeIcon>
                    <Text color="tertiary.5" size={14} weight={600}>
                      {name}
                    </Text>
                  </Group>
                </td>
                {probes.map(([identifier, types]) =>
                  types.map(type =>
                    renderOptional(
                      pipe(
                        R.lookup(identifier)(measures),
                        O.chain(A.findFirst(({ measure }) => measure.type === type)),
                      ),
                      ({ measure, alert }) => (
                        <td>
                          <Badge
                            c={alert ? Dashboard.AlertSensorsZone.levelColor[alert] : 'gray'}
                            bg={alert ? Dashboard.AlertSensorsZone.levelBg[alert] : 'gray.1'}
                            radius={2}>
                            <Text weight={600} size={14} lh={1.55}>
                              {alert === Threshold.Level.None ? 'OK' : MeasuresUtils.formatMeasure(measure)}
                            </Text>
                          </Badge>
                        </td>
                      ),
                      () => (
                        <td>
                          <Text color="dark.0"> - </Text>
                        </td>
                      ),
                    ),
                  ),
                )}
                <td style={{ background: 'transparent', padding: 0, paddingLeft: 7 }}>
                  <ActionIcon
                    component={Link}
                    to={`sensors/list/${type}/${id}`}
                    size={36}
                    color="tertiary.2"
                    c="primary.5"
                    bg="gray.0"
                    style={{ border: 'none' }}>
                    <IconEye strokeWidth={1} size={22} />
                  </ActionIcon>
                </td>
              </tr>
            )),
          )}
        </tbody>
        <caption style={{ marginTop: 3 }}>
          <Group spacing={9}>
            {probes.map(([identifier], index) => (
              <Text key={identifier} color="gray.6" size={8} weight={600}>
                {A.replicate(index + 1, '*')}
                {Dashboard.AlertSensorsZone.identifierCaption[identifier]}
              </Text>
            ))}
          </Group>
        </caption>
      </Table>
    </Box>
  );
};
export default DashboardAlertsTable;
