import React, { CSSProperties, FC } from 'react';
import { Card, Stack, Text } from '@mantine/core';
import { renderNullable } from '@shared/utils/render';
import { AlertReport } from '@modules/alert-reports/model';
import { StackProps } from '@mantine/core/lib/Stack/Stack';

interface CommentsCardsProps {
  alertReport: AlertReport;
  style?: CSSProperties;
}

const CommentsCards: FC<CommentsCardsProps & StackProps> = ({ alertReport, ...rest }) => {
  return (
    <Stack spacing={10} mb="auto" {...rest}>
      {/* is my own private comment */}
      {alertReport.canEdit && (
        <Card
          sx={{ '&[data-with-border]': { borderColor: '#F5F4F2' }, whiteSpace: 'pre-wrap' }}
          px={13}
          pt={10}
          pb={12}
          radius={8}>
          <Text size={10} color="dark.1" fw={600} pb={6}>
            Commentaire interne
          </Text>
          {renderNullable(alertReport.internalComment, internal => (
            <Text size={12} color="dark.5">
              {internal}
            </Text>
          ))}
        </Card>
      )}

      <Card
        sx={{ '&[data-with-border]': { borderColor: '#F5F4F2' }, whiteSpace: 'pre-wrap' }}
        radius={8}
        px={13}
        pt={10}
        pb={12}>
        <Text size={10} color="dark.1" fw={600} pb={6}>
          Commentaire public
        </Text>
        {renderNullable(alertReport.comment, comment => (
          <Text size={12} color="dark.5">
            {comment}
          </Text>
        ))}
      </Card>
    </Stack>
  );
};

export default CommentsCards;
