import React, { FC, useEffect } from 'react';
import { createBrowserRouter, createRoutesFromElements, Outlet, Route, useNavigation } from 'react-router-dom';

import { createRoute } from '@core/router';

import { resetNavigationProgress, startNavigationProgress } from '@mantine/nprogress';
import ErrorPage from '@shared/components/error-page/ErrorPage';
import profilePageRoute from '@modules/profile/ProfilePage';

import usersRoutes from '@modules/users/routes';
import profileLoaderRoute from '@modules/profile/loader';
import logoutRoute from '@modules/auth/logout';
import iotRoutes from '@modules/iot/routes';
import authRoutes from '@modules/auth/routes';
import passwordsRoutes from '@modules/passwords/routes';
import dashBoardRoutes from '@modules/dashboard/routes';
import layoutRoute from '@layout/Layout';
import alertReportRoutes from '@modules/alert-reports/routes';
import hydrasoloRoutes from '@modules/iot/hydrasolo/routes';

const NavigationLoader: FC = () => {
  const navigation = useNavigation();

  useEffect(() => {
    if (navigation.state !== 'idle') {
      startNavigationProgress();
    } else {
      resetNavigationProgress();
    }
  }, [navigation.state]);

  return <Outlet />;
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<NavigationLoader />}>
      {authRoutes}
      {passwordsRoutes}

      <Route {...createRoute(profileLoaderRoute)}>
        <Route {...createRoute(layoutRoute)}>
          <Route path="/profile" {...createRoute(profilePageRoute)} />

          {dashBoardRoutes}
          {iotRoutes}
          {hydrasoloRoutes}
          {alertReportRoutes}
          {usersRoutes}

          <Route path="/logout" {...createRoute(logoutRoute)} />

          <Route path="*" element={<ErrorPage />} />
        </Route>

        <Route path="*" element={<ErrorPage />} />
      </Route>

      <Route path="*" element={<ErrorPage />} />
    </Route>,
  ),
);

export default router;
