import React, { FC, PropsWithChildren } from 'react';
import { MantineColor, Text } from '@mantine/core';

interface DashboardCaptionProps {
  color: MantineColor;
}

const DashboardCaption: FC<PropsWithChildren<DashboardCaptionProps>> = ({ color, children }) => {
  return (
    <Text
      size={10}
      weight={600}
      sx={theme => ({
        ':after': { content: "''", width: 10, height: 10, background: theme.fn.themeColor(color), borderRadius: '50%' },
        display: 'flex',
        alignItems: 'center',
        columnGap: 4,
      })}>
      {children}
    </Text>
  );
};

export default DashboardCaption;
