import React, { FC, Fragment } from 'react';
import { Card, SimpleGrid, Skeleton, Text } from '@mantine/core';
import { pipe } from 'fp-ts/function';
import * as R from 'fp-ts/Record';
import * as A from 'fp-ts/Array';
import { Sensor } from '@modules/iot/sensors/model';
import { Link } from 'react-router-dom';
import { Dashboard } from '@modules/iot/dashboard/model';
import * as Ord from 'fp-ts/Ord';
import * as N from 'fp-ts/number';
import DashboardSensorsCard from '@modules/iot/dashboard/components/DashboardSensorsCard';
import Await from '@core/router/components/Await';

const countByTypeOrd = Ord.tuple(Ord.trivial, N.Ord);

interface DashboardActiveSensorsCardProps {
  dashboard: Promise<Dashboard>;
}

const DashboardActiveSensorsCard: FC<DashboardActiveSensorsCardProps> = ({ dashboard }) => {
  return (
    <Await
      resolve={dashboard}
      fallback={
        <DashboardSensorsCard h={175}>
          <Skeleton style={{ flex: 2 }} radius={14} />
        </DashboardSensorsCard>
      }>
      {({ sensors }) => (
        <DashboardSensorsCard
          countContent={
            <>
              <Text size={34} lh={1.3}>
                {sensors.total}
              </Text>
              <Text size={14}>Total Sonde(s)</Text>
            </>
          }>
          <Card bg="gray.0" withBorder={false} style={{ flex: 2 }}>
            <SimpleGrid cols={2} fw={600}>
              {pipe(
                sensors.countByType,
                R.toEntries,
                A.sort(countByTypeOrd),
                A.map(([type, count]) => (
                  <Fragment key={type}>
                    <Text color="dark.4" sx={theme => ({ borderRight: `1px solid ${theme.colors.gray[2]}` })}>
                      {Sensor.typeTitle[type]} :
                    </Text>
                    <Text color="dark.4" size={14}>
                      {count} sonde{count > 1 && 's'}
                    </Text>
                  </Fragment>
                )),
              )}
            </SimpleGrid>
            <Text
              component={Link}
              weight={600}
              to="/iot/sensors"
              color="primary.5"
              size={14}
              display="block"
              mt={16}
              td="underline">
              Voir le parc de sondes
            </Text>
          </Card>
        </DashboardSensorsCard>
      )}
    </Await>
  );
};

export default DashboardActiveSensorsCard;
