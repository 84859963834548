import React, { FC, useEffect, useState } from 'react';
import { useLocation, useMatches, useOutlet } from 'react-router-dom';
import { defineRoute, usePreserveNavigate } from '@core/router';
import { ActionIcon, Anchor, Button, Card, Checkbox, Group, Modal, Stack, Text, Title } from '@mantine/core';
import FiltersContent from '@modules/alert-reports/filters/components/FiltersContent';
import { IconPencil, IconSquareRoundedPlus } from '@tabler/icons-react';
import { defineLoader, httpTaskToResponseTask, useLoader } from '@core/router/loader';
import { AlertReportUtils } from '@modules/alert-reports/utils';
import { parseQueries, parseQueriesFormUrl, stringifyQueries } from '@shared/utils/queries';
import { constNull, pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import { AlertReport } from '@modules/alert-reports/model';
import * as IOO from 'fp-ts/IOOption';
import * as NEA from 'fp-ts/NonEmptyArray';
import PreserveSearchLink from '@core/router/components/PreserveSearchLink';
import DrawerContent from '@shared/components/drawer/DrawerContent';
import { HttpTask } from '@core/http';
import * as TE from 'fp-ts/TaskEither';
import { AlertReportsService } from '@modules/alert-reports/service';
import { sequenceS } from 'fp-ts/Apply';
import { defineAction } from '@core/router/action';
import { AlertReportSchema } from '@modules/alert-reports/schema';
import FullDrawer from '@shared/components/drawer/FullDrawer';

const filtersLoader = defineLoader({
  handler: ({ request }) => {
    const filters = AlertReportUtils.alertReportsFilterParser(parseQueriesFormUrl(request.url));

    return pipe(
      O.fromNullable(filters.filterId),
      O.fold<AlertReport.Filter.Id, HttpTask<AlertReport.Filter.Detail | null>>(
        () => TE.right(null),
        id => AlertReportsService.Filter.getFilter(id),
      ),
      TE.chain(userFilter =>
        sequenceS(TE.ApplyPar)({
          subcategories: AlertReportUtils.getNullableSubCategories(userFilter?.categoryId ?? filters.categoryId),
          types: AlertReportUtils.getNullableTypes(userFilter?.subCategoryId ?? filters.subCategoryId),
          filters: AlertReportsService.Filter.getFilters(),
          categories: AlertReportsService.Referential.getCategories(),
          markers: AlertReportsService.getMapAlertReports(filters),
          userFilter: TE.right(userFilter),
        }),
      ),
      httpTaskToResponseTask,
    );
  },
});

export const alertReportActions = {
  create: defineAction({
    type: 'create',
    payload: AlertReportSchema.filterSchema,
    handler: ({ payload }) => AlertReportsService.Filter.createFilter(payload),
    flashOptions: {
      success: () => 'Création réalisée',
    },
  }),
};
const FiltersListPage: FC = () => {
  const outlet = useOutlet();
  const { filters: userFilters, userFilter, categories, subcategories, types } = useLoader<typeof filtersLoader>();
  const { search } = useLocation();
  const navigate = usePreserveNavigate();

  const [filters, setFilters] = useState(() => AlertReportUtils.alertReportsFilterParser(parseQueries(search)));

  useEffect(() => {
    setFilters(AlertReportUtils.alertReportsFilterParser(parseQueries(search)));
  }, [search]);

  useEffect(() => {
    pipe(
      userFilter,
      IOO.fromNullable,
      IOO.chainIOK(
        ({ id, name, ...rest }) =>
          () =>
            setFilters({ ...rest, filterId: id }),
      ),
    )();
  }, [userFilter]);

  const handleApplyFilters = (filterToApply: Partial<AlertReport.Filter>) => {
    const newFilters = { ...filters, ...filterToApply, filterId: null };

    setFilters(newFilters);

    navigate({ search: stringifyQueries(newFilters) }, { replace: true });
  };

  const handleUserFilterChange = (ids: Array<AlertReport.Filter.Id>) =>
    navigate(
      { search: stringifyQueries({ filterId: pipe(NEA.fromArray(ids), O.fold(constNull, NEA.last)) }) },
      { replace: true },
    );

  const handleResetFilters = () => handleApplyFilters(AlertReport.defaultFilter);

  const handleClose = () => navigate('..', { replace: true, relative: 'path' });

  const matches = useMatches();

  const isModalOutlet = matches.some(({ id }) => id.includes(AlertReport.Filter.RouterId.Save));
  const isDrawerOutlet = matches.some(
    ({ id }) => id.includes(AlertReport.Filter.RouterId.New) || id.includes(AlertReport.Filter.RouterId.Update),
  );

  return (
    <>
      <DrawerContent
        top={
          <Group>
            <Title size="h3" sx={{ flex: '1 1 auto' }}>
              Filtres
            </Title>

            <Anchor component="button" c="dark.3" size="sm" fw={600} onClick={handleResetFilters}>
              Réinitialiser
            </Anchor>
          </Group>
        }>
        <FiltersContent
          applyFilters={handleApplyFilters}
          filters={filters}
          categories={categories}
          subcategories={subcategories}
          types={types}>
          <Group position="left" my={8}>
            <Button
              leftIcon={<IconSquareRoundedPlus />}
              variant="subtle"
              c="dark.3"
              color="white"
              component={PreserveSearchLink}
              to="save">
              Enregistrer la sélection de filtre
            </Button>
          </Group>
          <Card p={20} bg="tertiary.2" radius={8} withBorder={false}>
            <Text tt="uppercase" color="dark.5" weight={600} size={14} lh={1.55} pb={8}>
              Appliquer un filtre
            </Text>

            <Stack spacing={8}>
              {userFilters.map(({ id, name }) => (
                <Checkbox.Group
                  key={id}
                  onChange={handleUserFilterChange}
                  value={filters.filterId ? [filters.filterId] : []}>
                  <Group position="apart">
                    <Checkbox
                      key={id}
                      value={id}
                      label={
                        <Text weight={600} size={14} lh={1.55} color="dark.5">
                          {name}
                        </Text>
                      }
                    />
                    <ActionIcon c="tertiary.5" component={PreserveSearchLink} to={id}>
                      <IconPencil strokeWidth={1.4} />
                    </ActionIcon>
                  </Group>
                </Checkbox.Group>
              ))}
            </Stack>

            <Button
              leftIcon={<IconSquareRoundedPlus />}
              variant="subtle"
              c="dark.3"
              color="white"
              mt={8}
              component={PreserveSearchLink}
              to="new">
              Créer un filtre
            </Button>
          </Card>
        </FiltersContent>
      </DrawerContent>

      <Modal
        opened={outlet != null && isModalOutlet}
        onClose={handleClose}
        withCloseButton={false}
        centered
        zIndex={220}
        styles={{
          body: {
            padding: '31px 22px',
          },
        }}>
        {outlet}
      </Modal>

      <FullDrawer
        opened={outlet != null && isDrawerOutlet}
        onClose={handleClose}
        width={530}
        scrollAreaComponent={undefined}>
        {outlet}
      </FullDrawer>
    </>
  );
};

const filtersListRoute = defineRoute({
  component: FiltersListPage,
  loader: filtersLoader,
  actions: alertReportActions,
});

export default filtersListRoute;
