import React, { FC } from 'react';
import { Box, Skeleton, Stack, Table, Text } from '@mantine/core';
import { Sensor } from '@modules/iot/sensors/model';
import { Dashboard } from '@modules/iot/dashboard/model';
import pendingPlacholder from '@assets/images/pending-placeholder.svg';
import { Link } from 'react-router-dom';
import { renderOptional } from '@shared/utils/render';
import * as NEA from 'fp-ts/NonEmptyArray';
import { SensorUtils } from '@modules/iot/sensors/utils';
import DashboardSensorsCard from '@modules/iot/dashboard/components/DashboardSensorsCard';
import Await from '@core/router/components/Await';
interface DashboardPendingSensorsCardProps {
  dashboard: Promise<Dashboard>;
}

const DashboardPendingSensorsCard: FC<DashboardPendingSensorsCardProps> = ({ dashboard }) => {
  return (
    <Await
      resolve={dashboard}
      fallback={
        <DashboardSensorsCard h={175}>
          <Stack style={{ flex: 0.7 }} pt={10}>
            <Skeleton h={15} />
            <Skeleton h={15} />
            <Skeleton h={15} />
          </Stack>
        </DashboardSensorsCard>
      }>
      {({ sensors }) => (
        <DashboardSensorsCard
          countContent={
            <Box c={sensors.pending.count === 0 ? 'secondary' : 'primary.4'}>
              <Text size={34} lh={1.3}>
                {sensors.pending.count}
              </Text>
              <Text size={14}>Sonde(s) à configurer</Text>
            </Box>
          }
          sx={{
            backgroundImage: sensors.pending.count === 0 ? `url(${pendingPlacholder})` : undefined,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center right',
          }}>
          {renderOptional(
            NEA.fromArray(sensors.pending.items),
            sensors => (
              <Box>
                <Table
                  fontSize={10}
                  maw={185}
                  sx={theme => ({
                    'tbody > tr > td': {
                      fontSize: theme.fontSizes.sm,
                      padding: '2px 10px',
                      borderTop: 'none',

                      ':first-child': {
                        paddingLeft: 0,
                      },
                    },

                    'tbody > tr:first-child > td': {
                      paddingTop: 8,
                    },
                    'thead > tr > th': {
                      fontWeight: 600,
                      color: theme.colors.dark[1],
                      borderBottom: `1px solid ${theme.colors.tertiary[3]}`,
                      padding: '0 10px 8px',
                      ':first-child': {
                        paddingLeft: 0,
                      },
                    },
                  })}>
                  <thead>
                    <tr>
                      <th>Numéro de série</th>
                      <th>Marque</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sensors.slice(0, 3).map(({ technicalId, serial, type }) => (
                      <tr key={technicalId}>
                        <Text component="td" size={14} color="dark.2" weight={600}>
                          {serial}
                        </Text>
                        <Text component="td" color="dark.5">
                          {Sensor.typeTitle[type]}
                        </Text>
                      </tr>
                    ))}
                  </tbody>
                </Table>

                <Text
                  mt={20}
                  size="sm"
                  weight={600}
                  color="primary.5"
                  component={Link}
                  to={SensorUtils.pendingSensorLinkBuilder(NEA.head(sensors))}
                  td="underline">
                  Tout voir
                </Text>
              </Box>
            ),
            () => (
              <Text color="dark.4" weight={700} size={14} maw={207}>
                Vos sondes à configurer apparaîtront ici
              </Text>
            ),
          )}
        </DashboardSensorsCard>
      )}
    </Await>
  );
};

export default DashboardPendingSensorsCard;
