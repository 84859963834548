import React, { FC, MouseEventHandler } from 'react';
import { Box, Group, SimpleGrid, Skeleton, Stack, Switch, Text } from '@mantine/core';

import { pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';
import * as A from 'fp-ts/Array';

import Page from '@layout/page/Page';
import { Pest } from '@modules/alert-reports/pests/model';
import PestCard from '@modules/alert-reports/dashboard/components/PestCard';
import { PestSchema } from '@modules/alert-reports/pests/schema';
import DiseaseChartCard from '@modules/alert-reports/components/DiseaseChartCard';
import { DashboardService } from '@modules/dashboard/service';
import { defineRoute } from '@core/router';
import Await from '@core/router/components/Await';
import { defer, defineLoader, httpTaskToResponseTask, useLoader } from '@core/router/loader';
import { defineAction, useAction } from '@core/router/action';
import { PestService } from '@modules/alert-reports/pests/service';
import DiseasePrevisions from '@shared/modules/previsions/components/disease/DiseasePrevisions';
import DiseaseCaption from '@shared/modules/previsions/components/disease/DiseaseCaption';
import DiseasePlaceholder from '@shared/modules/previsions/components/disease/DiseasePlaceholder';
import { DateFormat } from '@shared/modules/dates';

const loader = defineLoader({
  handler: () =>
    httpTaskToResponseTask(
      pipe(
        DashboardService.getContext(null),
        TE.map(context =>
          defer({
            context,
            disease: httpTaskToResponseTask(DashboardService.getDisease(context)),
            pests: httpTaskToResponseTask(PestService.getPests(context)),
          }),
        ),
      ),
    ),
});

const actions = {
  subscribe: defineAction({
    type: 'subscribe',
    payload: PestSchema.updateSubscription,
    handler: ({ payload }) => PestService.updateSubscription(payload.name, payload.value),
  }),
  hide: defineAction({
    type: 'hide',
    payload: PestSchema.updateVisibility,
    handler: ({ payload }) => PestService.updateVisibility(payload.name, payload.value),
  }),
};

const DashboardPage: FC = () => {
  const { pests, context, disease } = useLoader<typeof loader>();

  const [subscribeLoading, toggleSubscribe] = useAction(actions.subscribe);
  const [visibilityLoading, toggleVisibility] = useAction(actions.hide);

  const handleSubscribe =
    (pest: Pest.Description): MouseEventHandler<HTMLInputElement> =>
    e =>
      toggleSubscribe({ name: pest.enum, value: e.currentTarget.checked });

  const handleHide =
    (pest: Pest.Description): MouseEventHandler<HTMLInputElement> =>
    e =>
      toggleVisibility({ name: pest.enum, value: e.currentTarget.checked });

  return (
    <Page top="Tableau de bord Bioagresseurs" pl={47}>
      <Stack>
        <Stack spacing={10}>
          <Group position="apart">
            <Text c="dark.5" fw={700} size={18} lh={1.45}>
              Prévisions épidémiosurveillance
            </Text>

            <DiseaseCaption />
          </Group>
          <Await resolve={disease} fallback={<DiseasePlaceholder />}>
            {({ today, forecast }) => (
              <DiseasePrevisions
                main={today}
                days={forecast}
                location={context.location}
                formatStr={DateFormat.LocalDate}
              />
            )}
          </Await>
        </Stack>
        <Await
          resolve={disease}
          fallback={
            <>
              <Skeleton h={418} />
              <Skeleton h={67} mt={10} />
            </>
          }>
          {disease => (
            <Box pb={10}>
              <DiseaseChartCard days={disease.forecast} />
            </Box>
          )}
        </Await>
        <Stack spacing={10}>
          <Text c="dark.5" fw={700} lh={1.45}>
            Vigilance Bioagresseurs
          </Text>
          <SimpleGrid cols={2} spacing={30}>
            <Await resolve={pests}>
              {A.map(pest => (
                <PestCard key={pest.enum} pest={pest}>
                  <Stack spacing={10} fw={600} fz={14}>
                    <Switch
                      checked={pest.notifEnabled}
                      onClick={handleSubscribe(pest)}
                      disabled={subscribeLoading}
                      label="M'abonner aux notifications"
                      color="primary.5"
                      styles={{ track: { cursor: 'pointer' }, label: { cursor: 'pointer' } }}
                    />
                    <Switch
                      checked={pest.hidden}
                      onClick={handleHide(pest)}
                      disabled={visibilityLoading}
                      label="Masquer sur l'accueil"
                      color="primary.5"
                      styles={{ track: { cursor: 'pointer' }, label: { cursor: 'pointer' } }}
                    />
                  </Stack>
                </PestCard>
              ))}
            </Await>
          </SimpleGrid>
        </Stack>
      </Stack>
    </Page>
  );
};

const dashBoardRoute = defineRoute({
  component: DashboardPage,
  loader,
  actions,
});

export default dashBoardRoute;
