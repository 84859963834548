import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Select } from '@mantine/core';
import { useFetchTaskOption } from '@core/http/hooks';
import { Utils } from '@shared/utils/model';
import * as TE from 'fp-ts/TaskEither';
import { ActiveSensor, Sensor } from '@modules/iot/sensors/model';
import { HttpError } from '@core/http';
import { DateFormat, formatDate } from '@shared/modules/dates';
import { differenceInWeeks, endOfToday, startOfToday } from 'date-fns';
import { Measures } from '@shared/modules/measures/model';
import { Hortee } from '@modules/iot/sensors/hortee/model';
import { HorteeService } from '@modules/iot/sensors/hortee/service';
import { z } from 'zod';
import { getDropdownOptionsFromEnumLabels } from '@shared/utils/enum';
import HumidityMeasure from '@shared/modules/measures/HumidityMeasure';

interface HumidityMeasureProps {
  sensor: ActiveSensor.Impl<Sensor.Type.Hortee>;
  defaultSoilType: Hortee.SoilType;
}

const HumidityCharts: FC<HumidityMeasureProps> = ({ sensor, defaultSoilType }) => {
  const [soilType, setSoilType] = useState<Hortee.SoilType | null>(defaultSoilType);
  const [[startDate, endDate], setDateRange] = useState([startOfToday(), endOfToday()]);

  useEffect(() => {
    setSoilType(defaultSoilType);
  }, [defaultSoilType]);

  const filter: Measures.History.Filter = useMemo(
    () => ({
      startDate: formatDate(startDate, DateFormat.LocalDateTime),
      endDate: formatDate(endDate, DateFormat.LocalDateTime),
      unit: differenceInWeeks(endDate, startDate) < 1 ? Utils.ChronoUnit.Hours : Utils.ChronoUnit.Days,
    }),
    [startDate, endDate],
  );

  const measuresTask = useCallback(() => {
    const parse = z.nativeEnum(Hortee.SoilType).safeParse(soilType);
    return parse.success
      ? HorteeService.getHumidityMeasures(sensor.id, {
          ...filter,
          soilType: parse.data,
        })
      : TE.left(HttpError.default);
  }, [filter, sensor.id, soilType]);

  const [humidity] = useFetchTaskOption(measuresTask);

  const handleSoilChange = (key: Hortee.SoilType | null) => setSoilType(key);

  return (
    <HumidityMeasure
      humidity={humidity}
      setDateRange={setDateRange}
      dateRange={[startDate, endDate]}
      exportParams={{ type: sensor.type, ...filter }}>
      <Select
        data={getDropdownOptionsFromEnumLabels(Hortee.soilTypeLabel)}
        label="Simuler avec un autre type de sol"
        value={soilType}
        onChange={handleSoilChange}
        placeholder="Sélectionner"
        required
      />
    </HumidityMeasure>
  );
};

export default HumidityCharts;
