import React, { FC } from 'react';
import { EnhancedForm, useEnhancedForm } from '@shared/modules/form';
import { zodResolver } from '@hookform/resolvers/zod';
import { SensoterraSchema } from '@modules/iot/sensors/sensoterra/schema';
import PendingForm from '@modules/iot/sensors/sensoterra/components/PendingForm';
import Seo from '@shared/modules/seo/Seo';
import { Box, Card } from '@mantine/core';
import { SensoterraService } from '@modules/iot/sensors/sensoterra/service';
import { SensoterraService as IOTSensoterraService } from '@modules/iot/sensoterra/service';
import { defineRoute, preventActionLeave, usePreserveNavigate, withKeyObserver } from '@core/router';
import { Sensoterra } from '@modules/iot/sensoterra/model';
import { defineLoader, httpTaskToResponseTask, useLoader } from '@core/router/loader';
import { sequenceS } from 'fp-ts/Apply';
import * as TE from 'fp-ts/TaskEither';
import { Outlet } from 'react-router-dom';
import { ZonesService } from '@modules/iot/zones/service';
import { SensorsService } from '@modules/iot/sensors/service';
import PendingHeader from '@modules/iot/sensors/list/components/pending/PendingHeader';
import { SensoterraUtils } from '@modules/iot/sensors/sensoterra/utils';
import { Sensor } from '@modules/iot/sensors/model';
import { flow } from 'fp-ts/function';
import { SensorUtils } from '@modules/iot/sensors/utils';
import { defineAction, useAction } from '@core/router/action';

const loader = defineLoader({
  id: Sensor.SensorsRouteId.SensoterraPending,
  params: Sensor.sensorPendingParams,
  handler: ({ params }) => {
    return httpTaskToResponseTask(
      sequenceS(TE.ApplyPar)({
        sensor: SensorsService.getPendingSensor<Sensor.Type.Sensoterra>(params.id),
        parameters: IOTSensoterraService.getParameters(),
        zones: ZonesService.getZones(),
      }),
    );
  },
});

const actions = {
  update: defineAction({
    type: 'update',
    payload: SensoterraSchema.pendingSchema,
    handler: ({ payload }) => SensoterraService.updatePending(payload),
    flashOptions: {
      success: () => 'Configuration réalisée',
    },
  }),
};

const PendingDetailPage: FC = () => {
  const [loading, send] = useAction(actions.update);
  const { sensor, parameters, zones } = useLoader<typeof loader>();
  const navigate = usePreserveNavigate();

  const { formRef, form } = useEnhancedForm<Sensoterra.PendingParams>({
    resolver: zodResolver(SensoterraSchema.pendingSchema),
    defaultValues: SensoterraUtils.getPendingDefaultValues(sensor),
  });

  const handleUpdate = flow(
    send,
    TE.chainIOK(
      ({ id }) =>
        () =>
          navigate(SensorUtils.sensorLinkBuilder({ type: Sensor.Type.Sensoterra, id }), { replace: true }),
    ),
  );

  return (
    <>
      <Box h="100%">
        <Seo title="Configuration Sensoterra" />
        <PendingHeader type={sensor.type} measures={sensor.measures} />
        <Card
          p={22}
          radius={10}
          shadow="0px 4px 10px rgba(0, 0, 0, 0.05)"
          sx={theme => ({
            '&[data-with-border]': { border: `1px solid ${theme.colors.tertiary[2]}`, borderTop: 'none' },
          })}>
          <EnhancedForm ref={formRef} form={form} onSubmit={handleUpdate} preventLeave>
            <PendingForm
              serial={sensor.serial}
              parameters={parameters}
              loading={loading}
              zones={zones}
              depth={sensor.config.depth}
            />
          </EnhancedForm>
        </Card>
      </Box>
      <Outlet />
    </>
  );
};

const component = withKeyObserver<typeof loader>(PendingDetailPage, 'id');

export const sensoterraPendingRoute = defineRoute({
  component,
  actions,
  loader,
  shouldRevalidate: preventActionLeave<typeof actions>('update'),
});
