import { MantineColor } from '@mantine/core';
import { Measures } from '@shared/modules/measures/model';
import { Sensor } from '@modules/iot/sensors/model';
import { pipe, tuple } from 'fp-ts/function';
import * as A from 'fp-ts/Array';
import * as R from 'fp-ts/Record';
import { mix } from 'polished';
import { Disease as DiseaseModel } from '@shared/modules/disease/model';

export namespace Charts {
  export namespace Pie {
    export interface Data {
      name: string;
      value: number;
      bg: MantineColor;
      color: MantineColor;
    }
  }

  export namespace Line {
    export type SensorDataValues = {
      [Type in Measures.History.Type as `${Sensor.Probe<Sensor.Type>}.${Type}`]?: Measures.Value<Type>;
    };

    export interface SensorData extends SensorDataValues {
      date: Date;
    }

    export const sensorDataLabel: Record<keyof SensorDataValues, string> = pipe(
      Object.values(Measures.History.Type),
      A.chain(type =>
        Object.values(Sensor.Probe.Identifier).map(id =>
          tuple(`${id}.${type}`, `${Sensor.Probe.identifierLabel[id]} - ${Measures.typeTitle[type]}`),
        ),
      ),
      R.fromEntries,
    );

    export const sensorDataColor: Record<keyof SensorDataValues, MantineColor> = pipe(
      Object.values(Measures.History.Type),
      A.chain(type =>
        Object.values(Sensor.Probe.Identifier).map(id =>
          tuple(`${id}.${type}`, mix(0.5, Sensor.Probe.identifierColor[id], Measures.History.typeColor[type])),
        ),
      ),
      R.fromEntries,
    );

    export namespace Humidity {
      export type DataKey = 'soilHumidity';

      export const dataKeyLabel: Record<DataKey, string> = {
        soilHumidity: 'Humidité du sol',
      };
    }

    export namespace Disease {
      type Values = {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [Type in DiseaseModel.Model]?: number;
      };

      export interface Data extends Values {
        date: Date;
      }

      export const modelColor: Record<DiseaseModel.Model, MantineColor> = {
        [DiseaseModel.Model.ColdFusarium]: 'red.6',
        [DiseaseModel.Model.DollarSpot]: 'violet.6',
        [DiseaseModel.Model.LWD]: 'teal.6',
      };
    }
  }
}
