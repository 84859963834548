import React, { FC } from 'react';
import { Tooltip, Image } from '@mantine/core';
import Pin from '@shared/modules/maps/components/Pin';
import { AlertReport } from '@modules/alert-reports/model';
import { AlertReportUtils } from '@modules/alert-reports/utils';
import getFallbackImage = AlertReportUtils.getFallbackImage;
import { renderOptional } from '@shared/utils/render';
import { PGImageVariant } from '@shared/modules/images/model';
import PreserveSearchLink from '@core/router/components/PreserveSearchLink';

interface SensorPinProps {
  marker: AlertReport.Marker;
}

const AlertReportPin: FC<SensorPinProps> = ({ marker }) => {
  return (
    <Tooltip label={marker.type.label} position="top">
      <Pin
        component={PreserveSearchLink}
        to={`${marker.id}`}
        relative="path"
        display="block"
        w="100%"
        h="100%"
        color={marker.category.color}>
        {renderOptional(
          getFallbackImage(PGImageVariant.Cover_150x150, [
            marker.type.images,
            marker.subCategory.images,
            marker.category.images,
          ]),
          src => (
            <Image src={src} width={28} height={28} radius="50%" />
          ),
        )}
      </Pin>
    </Tooltip>
  );
};

export default AlertReportPin;
