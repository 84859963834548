import React, { FC } from 'react';
import { Threshold } from '@modules/iot/model';
import { Measures } from '@shared/modules/measures/model';
import ThresholdSlider from '@shared/modules/threshold/ThresholdSlider';
import * as A from 'fp-ts/Array';
import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import * as NEA from 'fp-ts/NonEmptyArray';
import { Box, Button, Group, Stack, Text, ThemeIcon } from '@mantine/core';
import { IconSquareRoundedPlus, IconTemperature } from '@tabler/icons-react';
import { renderOptional } from '@shared/utils/render';
import Type = Measures.Type;
import Level = Threshold.Level;
import { FieldErrors } from 'react-hook-form/dist/types';
import { LastMeasuresUtils } from '@shared/modules/measures/last/utils';
import MinMeasure = LastMeasuresUtils.MinMeasure;
import MaxMeasure = LastMeasuresUtils.MaxMeasure;
import NumericMeasure = LastMeasuresUtils.NumericMeasure;
import StepMeasure = LastMeasuresUtils.StepMeasure;
import { Utils } from '@shared/utils/model';
import Temperature = Utils.Temperature;

const type = Type.Temperature;
const last = Level.Critical;

type ThresholdType = Threshold.Scale<Measures.Value<typeof type>, Threshold.Level>;

interface TemperatureScaleEditProps {
  onChange(value: ThresholdType): void;
  values: ThresholdType;
  errors?: FieldErrors<Threshold.Scale<Measures.Value<NumericMeasure>, Threshold.Level>>;
}

const TemperatureScaleEdit: FC<TemperatureScaleEditProps> = ({ values, onChange, errors }) => {
  const sliderValues = A.chunksOf(2)(values.levels.map(({ until }) => until));
  const getMin = (index: number) =>
    pipe(
      A.lookup(index - 1)(sliderValues),
      O.fold(
        () => MinMeasure[type],
        prev => Temperature.parse(NEA.last(prev) + StepMeasure[type]),
      ),
    );

  const getMax = (index: number) =>
    pipe(
      A.lookup(index + 1)(sliderValues),
      O.fold(
        () => MaxMeasure[type],
        next => Temperature.parse(NEA.head(next) - StepMeasure[type]),
      ),
    );

  const handleChangeFromSlider = (values: typeof sliderValues) => {
    const levels = pipe(
      A.reverse(values),
      A.chainWithIndex((index, [fst, snd]) => [
        { until: snd, level: Level.Alert },
        { until: fst, level: index % 2 === 0 ? Level.None : last },
      ]),
      A.reverse,
    );

    onChange({ levels, last });
  };

  const handleChangeFromIndex = (index: number) => (values: NEA.NonEmptyArray<Measures.Value<typeof type>>) =>
    pipe(
      sliderValues,
      A.updateAt(index, values),
      O.getOrElse(() => sliderValues),
      handleChangeFromSlider,
    );

  const handleAddSlider = () => {
    const max = pipe(
      A.head(sliderValues),
      O.fold(() => MaxMeasure[type], NEA.head),
    );

    handleChangeFromSlider([[MinMeasure[type], max], ...sliderValues]);
  };

  const handleRemove = () =>
    pipe(
      A.tail(sliderValues),
      O.getOrElse(() => sliderValues),
      handleChangeFromSlider,
    );

  return (
    <Stack spacing={16}>
      {renderOptional(A.last(sliderValues), ([firstValue, sndValue]) => (
        <Box>
          <Group spacing={8} pb={18}>
            <ThemeIcon size={22} bg="tertiary.3" c="tertiary.5">
              <IconTemperature size={12} />
            </ThemeIcon>
            <Text color="dark.5" weight={600} size={12}>
              Température haute
            </Text>
          </Group>
          <ThresholdSlider
            type={type}
            min={getMin(sliderValues.length - 1)}
            max={getMax(sliderValues.length - 1)}
            lastLevel={last}
            onChange={handleChangeFromIndex(sliderValues.length - 1)}
            values={[firstValue, sndValue]}
            errors={[errors?.levels?.[sliderValues.length - 2], errors?.levels?.[sliderValues.length - 1]]}
          />
          {sliderValues.length < 2 && (
            <Button
              mt={10}
              px={4}
              onClick={handleAddSlider}
              variant="subtle"
              color="white"
              leftIcon={
                <ThemeIcon c="dark.3" size={28} bg="transparent">
                  <IconSquareRoundedPlus strokeWidth={1} />
                </ThemeIcon>
              }>
              <Text color="dark.3" size={14} weight={600}>
                Température basse
              </Text>
            </Button>
          )}
        </Box>
      ))}
      {sliderValues.length > 1 &&
        renderOptional(A.head(sliderValues), ([firstValue, sndValue]) => (
          <Box>
            <Group spacing={8} pb={18}>
              <ThemeIcon size={22} bg="tertiary.3" c="tertiary.5">
                <IconTemperature size={12} />
              </ThemeIcon>
              <Text color="dark.5" weight={600} size={12}>
                Température basse
              </Text>
            </Group>
            <ThresholdSlider
              type={type}
              min={getMin(0)}
              max={getMax(0)}
              lastLevel={Level.None}
              onChange={handleChangeFromIndex(0)}
              onDelete={handleRemove}
              values={[firstValue, sndValue]}
              errors={[errors?.levels?.[0], errors?.levels?.[1]]}
            />
          </Box>
        ))}
    </Stack>
  );
};

export default TemperatureScaleEdit;
