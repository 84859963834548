import React, { FC, MouseEventHandler } from 'react';
import { Card, Group, Stack, Text } from '@mantine/core';
import LineClampSpoiler from '@shared/components/spoiler/LineClampSpoiler';
import { IconSpeakerphone } from '@tabler/icons-react';

interface AdvicesCardProps {
  advice: string;
}

const AdviceCard: FC<AdvicesCardProps> = ({ advice }) => {
  const handleAdviceClick: MouseEventHandler = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <Stack spacing={5}>
      <Group spacing={5}>
        <IconSpeakerphone />
        <Text fz={18} fw={700} lh={1.45}>
          Conseils
        </Text>
      </Group>
      <Card
        bg="tertiary.2"
        fz={14}
        fw={400}
        lh={1.55}
        c="black"
        radius={10}
        withBorder={false}
        onClick={handleAdviceClick}
        px={18}
        py={20}>
        <LineClampSpoiler lineClamp={3}>{advice}</LineClampSpoiler>
      </Card>
    </Stack>
  );
};

export default AdviceCard;
