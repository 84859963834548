import React, { ForwardedRef, forwardRef, PropsWithChildren } from 'react';
import { Box, ThemeIcon } from '@mantine/core';
import { BoxProps } from '@mantine/core/lib/Box/Box';
import { PolymorphicComponentProps } from '@mantine/utils';
import { MantineColor, MantineNumberSize } from '@mantine/styles';

export type PinProps<C> =
  | PolymorphicComponentProps<C, BoxProps>
  | (PolymorphicComponentProps<'div', BoxProps> & { component?: never });

function Pin<C = 'div'>(
  {
    children,
    size = 36,
    color,
    ...props
  }: PropsWithChildren<PinProps<C>> & {
    size?: MantineNumberSize;
    color?: MantineColor;
  },
  ref: ForwardedRef<HTMLDivElement>,
) {
  return (
    <Box ref={ref} pos="relative" style={{ transform: `translate(-50%, calc(-100% - 10px))` }} {...props}>
      <ThemeIcon
        variant="filled"
        radius="xl"
        size={size}
        color={color}
        sx={theme => ({ border: `4px solid ${theme.white}`, boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)' })}>
        {children}
      </ThemeIcon>
      <Box
        pos="absolute"
        bottom={2}
        left="50%"
        bg="white"
        w={12}
        h={12}
        sx={{
          clipPath: 'polygon(0% 0%, 50% 100%, 100% 0%)',
          transform: `translate(-50%, 100%)`,
        }}
      />
    </Box>
  );
}

export default forwardRef(Pin);
