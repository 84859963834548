import * as NEA from 'fp-ts/NonEmptyArray';
import { Product } from '@modules/alert-reports/products/model';
import React, { FC } from 'react';
import { useDisclosure } from '@mantine/hooks';
import { ActionIcon, Box, Card, Divider, Group, Text } from '@mantine/core';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import { renderNullable } from '@shared/utils/render';
import { NumberUtils } from '@shared/utils/number';
import formatPrice = NumberUtils.formatPrice;

interface PackagesSpoilerProps {
  packages: NEA.NonEmptyArray<Product.Package>;
  index: number;
}
const PackagesSpoiler: FC<PackagesSpoilerProps> = ({ packages, index }) => {
  const [show, { toggle }] = useDisclosure(false);
  return (
    <Box w="100%">
      <Group spacing={0}>
        <Text
          component="label"
          htmlFor={`show-packages.${index}`}
          td="underline"
          fz={14}
          fw={600}
          lh={1.55}
          c="tertiary.8"
          pr={10}
          style={{ cursor: 'pointer' }}>
          Conditionnements et prix
        </Text>
        <ActionIcon id={`show-packages.${index}`} color="primary" onClick={toggle}>
          {show ? <IconChevronUp /> : <IconChevronDown />}
        </ActionIcon>
      </Group>
      <Card
        w="100%"
        radius={5}
        fz={14}
        bg="#FCF8F5"
        p={0}
        withBorder={false}
        h={show ? 'auto' : 0}
        mt={show ? 5 : 0}
        style={{ overflow: 'hidden' }}>
        <Box px={14} py={15}>
          {packages.map(({ label, price, oldPrice }, index) => (
            <>
              <Group key={index} position="apart">
                <Text>{label}</Text>
                <Text>
                  {renderNullable(price, formatPrice)}{' '}
                  {renderNullable(oldPrice, old => (
                    <s>({NumberUtils.formatPrice(old)})</s>
                  ))}
                </Text>
              </Group>
              {index < packages.length - 1 && <Divider my={10} />}
            </>
          ))}
        </Box>
      </Card>
    </Box>
  );
};

export default PackagesSpoiler;
