import React, { FC, PropsWithChildren, useEffect, useRef, useState } from 'react';
import { ActionIcon, Flex, Group } from '@mantine/core';

import { useEnhancedElementSize } from '@shared/components/spoiler/hooks';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import { useViewportSize } from '@mantine/hooks';

interface LineClampSpoilerProps {
  initialState?: boolean;
  lineClamp?: number;
  hideLabel?: string;
  showLabel?: string;
}

const LineClampSpoiler: FC<PropsWithChildren<LineClampSpoilerProps>> = ({
  initialState = false,
  lineClamp = 1,
  children,
}) => {
  const [show, setShowState] = useState(initialState);
  const [spoiler, setSpoilerState] = useState<boolean>(initialState);
  const { ref, offset, scroll } = useEnhancedElementSize();

  // need to prevent spoiler state change on show toggle, only on element size change
  const showRef = useRef(show);
  useEffect(() => {
    showRef.current = show;
  }, [show]);

  useEffect(() => {
    if (!showRef.current) setSpoilerState(offset.height < scroll.height || offset.width < scroll.width);
  }, [offset, children, scroll]);

  // reset on viewport change
  const viewport = useViewportSize();
  useEffect(() => {
    setShowState(initialState);
  }, [viewport, initialState]);

  const toggleShow = () => setShowState(old => !old);

  return (
    <Group pos="relative" align="start" noWrap>
      <Flex
        ref={ref}
        direction="column"
        sx={{
          overflow: 'hidden',
          p: {
            display: 'inline',
          },
        }}
        style={
          show
            ? {
                maxHeight: scroll.height,
              }
            : {
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: lineClamp,
                overflow: 'hidden',
                whiteSpace: 'initial',
              }
        }>
        {children}
      </Flex>
      {spoiler && <ActionIcon onClick={toggleShow}>{show ? <IconChevronUp /> : <IconChevronDown />}</ActionIcon>}
    </Group>
  );
};

export default LineClampSpoiler;
