import React, { FC } from 'react';
import { defineRoute } from '@core/router';
import { Button, Card, Group, Image, Stack, Text } from '@mantine/core';
import { z } from 'zod';
import { Sensor } from '@modules/iot/sensors/model';
import { defineLoader, httpTaskToResponseTask, useLoader } from '@core/router/loader';
import * as TE from 'fp-ts/TaskEither';
import { SensorsService } from '@modules/iot/sensors/service';
import { DateFormat, formatDate } from '@shared/modules/dates';
import { Utils } from '@shared/utils/model';
import * as D from 'fp-ts/Date';
import { parseISO, subDays } from 'date-fns';
import { pipe } from 'fp-ts/function';
import { Measures } from '@shared/modules/measures/model';
import { MeasuresUtils } from '@shared/modules/measures/utils';
import getMeasureFromList = MeasuresUtils.getMeasureFromList;
import BatteryThemeIcon from '@shared/modules/measures/BatteryThemeIcon';
import SignalThemeIcon from '@shared/modules/measures/SignalThemeIcon';
import typeLogo = Sensor.typeLogo;
import MeasureCards from '@shared/modules/measures/last/MeasureCards';
import * as R from 'fp-ts/Record';
import * as M from 'fp-ts/Monoid';
import * as A from 'fp-ts/Array';
import * as O from 'fp-ts/Option';
import * as S from 'fp-ts/string';
import * as NEA from 'fp-ts/NonEmptyArray';
import SimpleLineChart from '@modules/iot/sensors/map/components/SimpleLineChart';
import { Threshold } from '@modules/iot/model';
import { SensorUtils } from '@modules/iot/sensors/utils';
import PreserveSearchLink from '@core/router/components/PreserveSearchLink';
import { renderNullable, renderOptional } from '@shared/utils/render';
import { Charts } from '@shared/modules/charts/model';
import mergeDateMonoid = MeasuresUtils.mergeDateMonoid;
import { Sinafis } from '@modules/iot/sensors/sinafis/model';
import Percent = Utils.Percent;

const params = z.object({ id: Sensor.Id });

const loader = defineLoader({
  params,
  handler: ({ params }) => {
    const today = D.create();

    return pipe(
      SensorsService.getActiveSensor(params.id),
      TE.bindTo('sensor'),
      TE.bind('measures', ({ sensor }) =>
        SensorsService.getMeasures(sensor.id, sensor.type, {
          startDate: formatDate(subDays(today, 1), DateFormat.LocalDateTime),
          endDate: formatDate(today, DateFormat.LocalDateTime),
          unit: Utils.ChronoUnit.Hours,
        }),
      ),
      httpTaskToResponseTask,
    );
  },
});

const IotMapDetail: FC = () => {
  const { sensor, measures } = useLoader<typeof loader>();
  const realtimeMeasures = A.flatten(Object.values(sensor.measures ?? {}));

  const signal = getMeasureFromList(realtimeMeasures, Measures.Type.Signal);
  const battery = getMeasureFromList(realtimeMeasures, Measures.Type.Battery);

  const data = pipe(
    R.toEntries(measures),
    A.chain(([probe, measure]) =>
      pipe(
        measure,
        A.filter(
          (measure): measure is Measures.History.Measure<typeof Measures.History.Type.Humidity> =>
            measure.type === Measures.Type.Humidity,
        ),
        A.map(({ values, type }) =>
          values.reduce(
            (acc, curr) => ({
              ...acc,
              [curr.at]: { [`${probe}.${type}`]: curr.value },
            }),
            {},
          ),
        ),
      ),
    ),
    M.concatAll(mergeDateMonoid),
    R.collect(S.Ord)((at, values) => ({ date: parseISO(at), ...values })),
  );

  const dateFormatter = (date: Date) => formatDate(date, 'HH:mm');

  const getHumidityThresholds = (): Array<{
    threshold: Threshold.HumidityScale;
    key: keyof Charts.Line.SensorDataValues;
  }> => {
    switch (sensor.type) {
      case Sensor.Type.Sensoterra:
        return [
          {
            threshold: sensor.config.humidityThreshold,
            key: `${Sensor.Probe.Identifier.Ground}.${Measures.Type.Humidity}`,
          },
        ];
      case Sensor.Type.Hortee:
        return [
          {
            threshold: sensor.config.humidityThreshold,
            key: `${Sensor.Probe.Identifier.Ground}.${Measures.Type.Humidity}`,
          },
        ];
      case Sensor.Type.Sinafis:
        const filteredConfig: Record<keyof Sinafis.Config, NonNullable<Sinafis.Config[keyof Sinafis.Config]>> = pipe(
          sensor.config,
          R.filterMap(O.fromNullable),
        );

        return pipe(
          R.toEntries(filteredConfig),
          A.map(([key, { humidityThreshold }]) => ({
            threshold: humidityThreshold,
            key: `${Sinafis.Config.configIdentifier[key]}.${Measures.Type.Humidity}`,
          })),
        );
    }
  };

  const referenceArea = pipe(
    getHumidityThresholds(),
    A.filterMap(({ threshold: { levels, last }, key }) =>
      pipe(
        levels,
        A.findIndex(({ level }) => level === Threshold.Level.None),
        O.map(index =>
          pipe(
            levels,
            A.lookup(index - 1),
            O.fold(
              () => ({ min: Percent.parse(0), max: levels[index].until }),
              ({ until }) => ({ min: until, max: levels[index].until }),
            ),
          ),
        ),
        O.alt(() =>
          pipe(
            O.guard(last === Threshold.Level.None),
            O.chain(() => A.last(levels)),
            O.map(({ until }) => ({ min: until, max: Percent.parse(1) })),
          ),
        ),
        O.map(props => ({ ...props, key })),
      ),
    ),
  );

  return (
    <Stack spacing={14} p={20}>
      <Group spacing={8}>
        <Text color="primary.5" weight={700} size={22}>
          {sensor.name}
        </Text>
        <SignalThemeIcon signal={signal} />
        <BatteryThemeIcon battery={battery} />
      </Group>
      <Group position="apart" noWrap>
        {renderNullable(sensor.zone, ({ name }) => (
          <Card p={8} bg="tertiary.2" radius={8} withBorder={false}>
            <Text size={12} weight={600} color="gray.8">
              <Text component="span" color="dark.2">
                Groupe :{' '}
              </Text>
              {name}
            </Text>
          </Card>
        ))}
        <Image width={66} src={typeLogo[sensor.type]} ml="auto" />
      </Group>
      <MeasureCards sensor={sensor} withoutDate withoutDistance />
      {renderOptional(NEA.fromArray(data), data => (
        <Card
          px={14}
          py={0}
          sx={theme => ({ '&[data-with-border]': { border: `1px solid ${theme.colors.tertiary[2]}` } })}>
          <Text color="dark.1" size={10} weight={600} pt={10}>
            Humidité de la journée
          </Text>
          <SimpleLineChart data={data} dateFormatter={dateFormatter} referenceArea={referenceArea} />
        </Card>
      ))}

      <Group position="right" pos="sticky">
        <Button component={PreserveSearchLink} to={SensorUtils.sensorLinkBuilder(sensor)}>
          Voir le détail
        </Button>
      </Group>
    </Stack>
  );
};

const iotMapDetailRoute = defineRoute({
  component: IotMapDetail,
  loader,
});

export default iotMapDetailRoute;
