import React from 'react';
import Slider from 'rc-slider';
import { ActionIcon, Box, Group, MantineColor, NumberInput, Text } from '@mantine/core';
import { Measures } from '@shared/modules/measures/model';
import { LastMeasuresUtils } from '@shared/modules/measures/last/utils';
import NumericMeasure = LastMeasuresUtils.NumericMeasure;
import formatter = LastMeasuresUtils.formatter;
import { useTheme } from '@emotion/react';
import { Threshold } from '@modules/iot/model';
import Level = Threshold.Level;
import { pipe } from 'fp-ts/function';
import * as A from 'fp-ts/Array';
import * as O from 'fp-ts/Option';
import 'rc-slider/assets/index.css';
import { IconTrash } from '@tabler/icons-react';
import { renderNullable } from '@shared/utils/render';
import { FieldErrors } from 'react-hook-form/dist/types';
import StepMeasure = LastMeasuresUtils.StepMeasure;
import { ThresholdUtils } from '@shared/modules/threshold/utils';
import numericFormatter = ThresholdUtils.numericFormatter;
import numericParser = ThresholdUtils.numericParser;

interface ThresholdSliderProps<Type extends NumericMeasure> {
  type: Type;
  values: [Measures.Value<Type>, Measures.Value<Type>];
  max: Measures.Value<Type>;
  min: Measures.Value<Type>;
  onChange(values: [Measures.Value<Type>, Measures.Value<Type>]): void;
  onDelete?(): void;
  lastLevel: Level.None | Level.Critical;
  errors?: FieldErrors<Threshold.Scale<Measures.Value<NumericMeasure>, Threshold.Level>['levels']>;
}

const sliderColor: Record<Level, MantineColor> = {
  [Level.None]: 'green.7',
  [Level.Alert]: 'yellow.5',
  [Level.Critical]: 'primary.5',
};

function ThresholdSlider<Type extends NumericMeasure>({
  values,
  max,
  min,
  type,
  onChange,
  onDelete,
  lastLevel,
  errors,
}: ThresholdSliderProps<Type>) {
  const [firstValue, sndValue] = values;
  const theme = useTheme();

  const precisionUnit = -Math.log10(StepMeasure[type]);
  const precision = precisionUnit < 0 ? 1 : precisionUnit;

  const handleSliderChange = (value: number | number[]) =>
    onChange((Array.isArray(value) ? value : [firstValue, sndValue]) as typeof values);

  const firstBreakpoint = ((firstValue - min) * 100) / (max - min);
  const sndBreakpoint = ((sndValue - min) * 100) / (max - min);
  const firstLevel = lastLevel === Level.None ? Level.Critical : Level.None;

  const handleInputChange = (values: [Measures.Value<Type>, Measures.Value<Type>], i: number) => (value: number) =>
    onChange(
      pipe(
        values,
        A.updateAt(i, value),
        O.getOrElseW(() => values),
      ) as typeof values,
    );

  return (
    <Box pos="relative">
      <Slider
        range
        allowCross={false}
        pushable={StepMeasure[type]}
        value={[firstValue, sndValue]}
        trackStyle={{ backgroundColor: 'transparent' }}
        railStyle={{
          background: `linear-gradient(to right, ${theme.fn.themeColor(
            sliderColor[firstLevel],
          )} ${firstBreakpoint}%, ${theme.fn.themeColor(
            sliderColor[Level.Alert],
          )} ${firstBreakpoint}% ${sndBreakpoint}%, ${theme.fn.themeColor(sliderColor[lastLevel])} ${sndBreakpoint}%)`,
        }}
        handleStyle={{ opacity: 1, border: `1px solid ${theme.colors.primary[3]}` }}
        draggableTrack
        max={max}
        min={min}
        step={StepMeasure[type]}
        onChange={handleSliderChange}
      />
      {values.map((until, index) => (
        <Text
          key={index}
          pos="absolute"
          color="dark.2"
          size={12}
          weight={600}
          left={`${((until - min) * 100) / (max - min)}%`}
          sx={{ transform: 'translateX(-50%)' }}>
          {formatter(until, type)}
        </Text>
      ))}
      <Group spacing={6} pt={25}>
        {values.map((until, index) => (
          <NumberInput
            precision={precision}
            key={index}
            value={until}
            formatter={value => numericFormatter(parseFloat(value) as Measures.Value<typeof type>, type)}
            parser={value => numericParser(parseFloat(value) as Measures.Value<typeof type>, type)}
            step={StepMeasure[type]}
            onChange={handleInputChange(values, index)}
            w={70}
            min={pipe(
              A.lookup(index - 1)(values),
              O.fold(
                () => min,
                prev => prev + StepMeasure[type],
              ),
            )}
            max={pipe(
              A.lookup(index + 1)(values),
              O.fold(
                () => max,
                next => next - StepMeasure[type],
              ),
            )}
            error={!!errors?.[index]}
          />
        ))}
        {renderNullable(onDelete, onDelete => (
          <ActionIcon c="tertiary.4" size={36} onClick={onDelete}>
            <IconTrash size={20} />
          </ActionIcon>
        ))}
      </Group>
    </Box>
  );
}

export default ThresholdSlider;
