import React, { FC, PropsWithChildren } from 'react';

import * as Styled from './LightDrawer.styles';
import { useClickOutside } from '@mantine/hooks';
import { CardProps, Card, Portal, Transition } from '@mantine/core';
import { constVoid } from 'fp-ts/function';

interface LightDrawerProps extends CardProps {
  opened: boolean;
  width?: number;
  bottom?: number;
  onClose?: () => void;
}

const LightDrawer: FC<PropsWithChildren<LightDrawerProps>> = ({
  opened,
  width = 'fit-content',
  bottom,
  onClose = constVoid,
  children,
  ...rest
}) => {
  const handleClickOutside = () => {
    if (opened) {
      onClose();
    }
  };

  const ref = useClickOutside(handleClickOutside);

  return (
    <Portal>
      <Transition mounted={opened} transition="slide-left" timingFunction="ease">
        {styles => (
          <Styled.LightDrawerContainer ref={ref} style={{ ...styles, width, bottom }}>
            <Card
              radius={10}
              bg="white"
              shadow="0 4px 7px rgba(0, 0, 0, 0.15)"
              p={0}
              withBorder={false}
              mah="100%"
              sx={{ overflow: 'auto' }}
              {...rest}>
              {children}
            </Card>
          </Styled.LightDrawerContainer>
        )}
      </Transition>
    </Portal>
  );
};

export default LightDrawer;
